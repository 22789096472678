.c-small-document-card {
    display: flex;
    align-items: center;
    min-height: 64px;
    padding: var(--y-nus-25) var(--x-nus-35);
    background-color: rgb(var(--rgb-grey-background));

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &__picture {
        flex-shrink: 0;
        width: 65px;
        height: 50px;
        margin-right: 30px;
        overflow: hidden;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
        margin-bottom: 5px;
    }

    &__title {
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &__subtitle{
        display: block;
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-grey-note));
        margin-top: 5px;
    }

    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 39px;
        height: 39px;
        background-color: rgb(var(--rgb-white));
        border-radius: 50%;
        transition: background-color 300ms;
    }

    &:hover &__svg-container {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__svg {
        width: 21px;
        height: 21px;
        fill: rgb(var(--rgb-blue-navy));
        transition: fill 300ms, stroke 300ms;

        &--stroke {
            stroke: rgb(var(--rgb-blue-navy));
        }
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-white));

        &--stroke {
            stroke: rgb(var(--rgb-white));
        }
    }
}
