.c-event-add-to-calendar-options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.5em;
    }

    &__title {
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.5em;
    }

    &__container {
        margin-left: 20px;
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__list-item:not(:last-child) {
        margin-right: 12px;
    }

    &__option {
        display: block;
        transition: opacity 300ms;
    }

    &__option:hover {
        opacity: 0.6;
    }

    &__option-img {
        height: 22px;
        vertical-align: bottom;
    }
}
