.c-breadcrumbs-and-button-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--y-30);
    border-bottom: 1px solid rgb(var(--rgb-blue-navy));

    &__breadcrumbs {
        flex: 1;
        display: flex;
        align-items: center;
        min-width: 0;
    }

    &__breadcrumbs-home {
        flex-shrink: 0;
        display: inline-flex;
    }

    @at-root .c-page-title--in-page-maintenance &__breadcrumbs-home {
        pointer-events: none;
    }

    &__breadcrumbs-home-svg {
        width: 12px;
        height: 12px;
        fill: rgb(var(--rgb-blue-navy));
        transition: fill 300ms;
    }

    &__breadcrumbs-home:hover &__breadcrumbs-home-svg {
        fill: rgb(var(--rgb-blue-hover));
    }

    &__breadcrumbs-texts {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__breadcrumbs-link-container,
    &__breadcrumbs-text-container {
        margin-left: 10px;

        &::before {
            content: '|';
            font-size: var(--fz-13);
            line-height: 1.4em;
            color: rgb(var(--rgb-blue-navy));
            margin-right: 10px;
        }
    }

    &__breadcrumbs-text-container:last-child {
        &::before {
            font-weight: 900;
            color: rgb(var(--rgb-red));
        }
    }

    &__breadcrumbs-link,
    &__breadcrumbs-text {
        font-size: var(--fz-13);
        line-height: 1.4em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__breadcrumbs-link:hover {
        color: rgb(var(--rgb-blue-hover));
    }

    &__breadcrumbs-text-container:last-child &__breadcrumbs-text {
        font-weight: 600;
    }

    &__button-share {
        margin-left: var(--x-85);

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    @at-root .c-page-title--in-page-maintenance &__button-share {
        display: none;
    }
}
