@use "../../abstract/placeholders";

.c-search-filter-field {
    height: 100%;

    &__input-container {
        position: relative;
        height: 100%;
    }

    &__input {
        font-family: var(--ff);
        font-size: var(--fz-16);
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
        width: 100%;
        height: 100%;
        padding: 20px calc(var(--x-nus-40) + 84px) 20px var(--x-nus-40);
        background-color: rgb(var(--rgb-grey-background));
        border: 0;
        box-shadow: 0 0 0 100px rgb(var(--rgb-grey-background)) inset;
        overflow: hidden;
        transition: color 300ms;

        &::-webkit-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
        &::-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
        &:-ms-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
        &:-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
        &::placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
    }

    &__button-delete {
        position: absolute;
        right: calc(var(--x-nus-40) + 36px);
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms;

        // Note : ce before sert à augmenter la zone de touch du bouton
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;
        }
    }

    &__input:not(:placeholder-shown) ~ &__button-delete {
        opacity: 1;
        pointer-events: all;
    }

    &__button-delete-x {
        @extend %x;
        --x-size: 18px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-blue-navy));
        --x-border-radius: 2px;
    }

    &__button-delete:hover &__button-delete-x {
        --x-color: rgb(var(--rgb-blue-hover));
    }

    &__button-submit {
        position: absolute;
        right: var(--x-nus-40);
        top: 50%;
        transform: translateY(-50%);
        display: flex;

        // Note : ce before sert à augmenter la zone de touch du bouton
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;
        }
    }

    &__button-submit-svg {
        width: 18px;
        height: 18px;
        stroke: rgb(var(--rgb-blue-navy));
        transition: stroke 300ms;
    }

    &__button-submit:hover &__button-submit-svg {
        stroke: rgb(var(--rgb-blue-hover));
    }
}
