.c-pagination-list-pages {
    padding-top: var(--y-25);
    border-top: 1px solid rgb(var(--rgb-blue-navy));

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__first,
    &__last,
    &__prev,
    &__next {
        position: relative;
        width: 23px;
        height: 23px;
    }

    &__first--disabled,
    &__last--disabled,
    &__prev--disabled,
    &__next--disabled {
        pointer-events: none;
    }

    &__first-svg,
    &__last-svg,
    &__prev-svg,
    &__next-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 9px;
        height: 14px;
        stroke: rgb(var(--rgb-blue-navy));
        transition: stroke 300ms;
    }

    &__first-svg:nth-child(1),
    &__last-svg:nth-child(1) {
        left: calc(50% - 3.5px);
    }

    &__first-svg:nth-child(2),
    &__last-svg:nth-child(2) {
        left: calc(50% + 3.5px);
    }

    &__first-svg,
    &__prev-svg {
        transform: translate(-50%, -50%) rotate(180deg);
    }

    &__first--disabled &__first-svg,
    &__last--disabled &__last-svg,
    &__prev--disabled &__prev-svg,
    &__next--disabled &__next-svg {
        stroke: rgba(var(--rgb-blue-dark-navy), 0.5);
    }

    &__first:hover &__first-svg,
    &__last:hover &__last-svg,
    &__prev:hover &__prev-svg,
    &__next:hover &__next-svg {
        stroke: rgb(var(--rgb-blue-hover));
    }

    &__pages-list {
        display: flex;
        align-items: center;
        margin: 0 var(--x-nus-75);

        @media screen and (max-width: 500px) {
            margin: 0 15px;
        }
    }

    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        font-size: var(--fz-15);
        font-weight: 500;
        color: rgb(var(--rgb-blue-navy));
        transition: background-color 300ms, color 300ms;

        @media screen and (max-width: 500px) {
            width: 34px;
            height: 34px;
        }
    }

    &__pages-list-item:not(:last-child) &__page {
        margin-right: 6px;
    }

    &__page:hover {
        background-color: rgb(var(--rgb-grey-background));
    }

    &__page--active {
        background-color: rgb(var(--rgb-blue-hover));
        font-weight: 600;
        color: rgb(var(--rgb-white));
        pointer-events: none;
    }
}
