@use "../../abstract/functions" as *;
@use "../../base/typography";

.c-showcase-card {
    --showcase-card-image-ratio: 71.4286%; // 520/728
    --showcase-card-birds-decor-svg-width: 937px;

    @media screen and (max-width: 800px) {
        --showcase-card-birds-decor-svg-width: 782px;
    }

    &--in-aside-and-content {
        @media screen and (min-width: 1001px) {
            --showcase-card-image-ratio: 73.3753%; // 350/477
        }
    }
}

.c-showcase-card {
    display: flex;
    width: 100%;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

    // Picture
    &__picture-container {
        position: relative;
        width: 53.6364%; // 708/1320
        order: 1;

        @media screen and (max-width: 800px) {
            width: 100%;
            order: 0;
        }
    }

    &--in-aside-and-content &__picture-container {
        @media screen and (min-width: 1001px) {
            margin-top: var(--y-nus-30);
        }
    }

    &__picture {
        position: relative;
        // Note : Le height c'est pour éviter que l'image soit plus petite que le bloc content
        height: 100%;
        padding-bottom: var(--showcase-card-image-ratio);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(var(--rgb-blue-hover), 0.3);
            opacity: 0;
            transition: opacity 300ms;
            z-index: 1;
        }
    }

    &[href]:hover &__picture::before {
        opacity: 1;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // Content
    &__content {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: var(--y-nus-80) var(--x-nus-122) var(--y-nus-80) var(--x-nus-70);
        background-color: rgb(var(--rgb-blue-navy));
        margin: var(--y-nus-30) calc(0px - 6.6667%) var(--y-nus-30) 0; // 88/1320
        overflow: hidden;
        z-index: 2;

        @media screen and (max-width: 800px) {
            margin: 0;
        }
    }

    &--in-aside-and-content &__content {
        @media screen and (min-width: 1001px) {
            padding: var(--y-nus-55) var(--x-nus-50);
            margin: 0 calc(0px - 11.0227%) var(--y-nus-30) 0; // 97/880
        }
    }

    &__surtitle {
        display: inline-block;
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgb(var(--rgb-white));
        margin-bottom: var(--y-nus-20);
    }

    &__title {
        font-size: var(--fz-39);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &--in-aside-and-content &__title {
        @media screen and (min-width: 1001px) {
            font-size: var(--fz-28);
        }
    }

    &__text {
        display: inline-block;
        font-size: var(--fz-20);
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        margin-top: var(--y-nus-20);
    }

    &--in-aside-and-content &__text {
        @media screen and (min-width: 1001px) {
            font-size: var(--fz-17);
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-white;
        width: fit-content;
        margin-top: var(--y-nus-30);
    }

    &[href]:hover &__button {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-blue-hover));
        --button-border-color: rgb(var(--rgb-blue-hover));
    }

    // Birds decor
    &__birds-decor {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: -1;
    }

    &__birds-decor-svg {
        position: absolute;
        top: 40px;
        right: clamp(-193px, responsive-size(800px, 1440px, -170px, -193px), -170px);
        fill: rgba(var(--rgb-blue-dark-navy), 0.5);
        width: var(--showcase-card-birds-decor-svg-width);
        height: calc(var(--showcase-card-birds-decor-svg-width) * 1.0427); // 977/937

        @media screen and (max-width: 800px) {
            top: 20px;
            right: clamp(-160px, responsive-size(600px, 800px, -140px, -160px), -140px);
        }
    }
}
