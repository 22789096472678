.c-home-banner {
    --home-banner-image-mobile-ratio: 69.6970%; // 230/330
}

.c-home-banner {
    height: 100%;

    &__picture-container {
        position: relative;
        height: 100%;
    }

    &__picture-credits {
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: block;
        max-width: 81%;
        padding: 4px 10px;
        background-color: rgba(2, 17, 34, 0.7); // #021122
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        z-index: 1;

        @media screen and (max-width: 800px) {
            max-width: calc(100% - 20px);
        }
    }

    &__picture {
        height: 100%;

        // Note : En mobile on mets un ratio à l'image
        @media screen and (max-width: 800px) {
            padding-bottom: var(--home-banner-image-mobile-ratio);
        }
    }

    &__img {
        @media screen and (max-width: 800px) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}
