@use "../../abstract/placeholders";
@use "../../base/typography";

:root {
    --alert-index-0-height: 60px;
    --alert-index-1-height: 60px;
    --alert-container-height: 60px;
    --alerts-height: calc(var(--alert-index-0-height) + var(--alert-index-1-height));
}

.c-alerts {
    height: var(--alerts-height);
    transition: height 500ms var(--in-out-cubic);
    overflow: hidden;

    &__alert {
        position: relative;
        transition: height 500ms var(--in-out-cubic);
        overflow: hidden;
    }

    &__alert--green {
        --alert-rgb-color: var(--rgb-white);
        background-color: rgb(61, 140, 32); // #3D8C20
    }
    &__alert--yellow {
        --alert-rgb-color: var(--rgb-black);
        background-color: rgb(255, 210, 62); // #FFD23E
    }
    &__alert--red {
        --alert-rgb-color: var(--rgb-white);
        background-color: rgb(197, 23, 56); // #C51738
    }

    &__alert--green + &__alert--green::before,
    &__alert--yellow + &__alert--yellow::before,
    &__alert--red + &__alert--red::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1px;
        background-color: rgb(var(--alert-rgb-color));
        transition: background-color 300ms 500ms;
    }

    &__alert--green.is-hide + &__alert--green::before,
    &__alert--yellow.is-hide + &__alert--yellow::before,
    &__alert--red.is-hide + &__alert--red::before {
        background-color: transparent;
    }

    &__alert[data-index="0"] { height: var(--alert-index-0-height); }
    &__alert[data-index="1"] { height: var(--alert-index-1-height); }

    &__alert-container {
        display: flex;
        align-items: center;
        height: var(--alert-container-height);
        padding-right: calc(var(--x-nus-25) + 68px + var(--x-nus-30));
        padding-left: var(--x-nus-25);

        @media screen and (max-width: 600px) {
            padding-right: calc(var(--x-nus-25) + 13px + var(--x-nus-30));
        }
    }

    &__alert-svg {
        width: 20px;
        height: 19px;
        fill: rgb(var(--alert-rgb-color));
        margin-right: var(--x-nus-30);
    }

    &__alert-text {
        flex: 1;
        max-width: fit-content;
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: rgb(var(--alert-rgb-color));
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: opacity 300ms;
    }

    &__alert-container[href]:hover &__alert-text {
        @media screen and (max-width: 600px) {
            opacity: 0.6;
        }
    }

    &__alert-link {
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--alert-rgb-color));
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--alert-rgb-color), 0.5);
        transition: opacity 300ms;
        margin-left: var(--x-nus-30);

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__alert-container[href]:hover &__alert-link {
        opacity: 0.6;
    }

    &__alert-button-close {
        position: absolute;
        top: 0;
        right: var(--x-nus-25);
        display: flex;
        align-items: center;
        height: var(--alert-container-height);
        transition: opacity 300ms;
    }

    &__alert-button-close:hover {
        opacity: 0.6;
    }

    &__alert-button-close-text {
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--alert-rgb-color));
        margin-right: 6px;

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__alert-button-close-x {
        @extend %x;
        --x-size: 13px;
        --x-thickness: 2px;
        --x-color: rgb(var(--alert-rgb-color));
        --x-border-radius: 2px;
    }
}
