@use "../../base/typography";

.c-radio-tag-field {
    --radio-tag-field-color: rgb(var(--rgb-blue-navy));
    --radio-tag-field-background-color: rgba(var(--rgb-blue-navy), 0.1);
    --radio-tag-field-color-hover: rgb(var(--rgb-white));
    --radio-tag-field-background-color-hover: rgb(var(--rgb-blue-hover));
    --radio-tag-field-color-active: rgb(var(--rgb-white));
    --radio-tag-field-background-color-active: rgb(var(--rgb-blue-hover));
}

.c-radio-tag-field {
    position: relative;

    &__header {
        margin-bottom: 15px;

        .c-form-note {
            padding-bottom: 15px;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__options {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;

        &--vertical {
            display: block;
        }
    }

    &__label {
        display: flex;
        margin: 5px;
        position: relative;
        -webkit-tap-highlight-color: transparent;

        &--vertical {
            width: fit-content;
        }
    }

    &__text {
        flex: 1;
        font-family: var(--ff);
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: var(--radio-tag-field-color);
        padding: 4px 12px;
        background-color: var(--radio-tag-field-background-color);
        border-radius: 100px;
        transition: color 300ms, background-color 300ms;
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }
    }

    &__label &__text:hover{
        color: var(--radio-tag-field-color-hover);
        background-color: var(--radio-tag-field-background-color-hover);

        a {
            span {
                box-shadow: inset 0 -0.1em 0 0 var(--radio-tag-field-color-hover);
                color: var(--radio-tag-field-color-hover);
                transition: opacity 300ms, color 300ms;
            }
            svg {
                fill: var(--radio-tag-field-color-hover);
                transition: opacity 300ms, fill 300ms;
            }

            &:hover {
                span {
                    box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-white), 0.7);
                }
                span, svg {
                    opacity: 0.7;
                }
            }
        }
    }

    // Input checked
    &__input:checked ~ &__text {
        color: var(--radio-tag-field-color-active);
        background-color: var(--radio-tag-field-background-color-active);

        a {
            span {
                box-shadow: inset 0 -0.1em 0 0 var(--radio-tag-field-color-active);
                color: var(--radio-tag-field-color-active);
                transition: opacity 300ms, color 300ms;
            }
            svg {
                fill: var(--radio-tag-field-color-active);
                transition: opacity 300ms, fill 300ms;
            }

            &:hover {
                span {
                    box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-white), 0.7);
                }
                span, svg {
                    opacity: 0.7;
                }
            }
        }
    }

    // Input disabled
    &__input:disabled ~ &__text {
        opacity: 0.6;
        pointer-events: none;
    }
}
