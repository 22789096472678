@use "../../../base/typography";

.c-likerts-survey-field {
    --likerts-survey-field-dot-color: rgb(var(--rgb-white));
    --likerts-survey-field-box-background-color: rgb(var(--rgb-white));
    --likerts-survey-field-box-background-color-active: rgb(var(--rgb-blue-hover));
    --likerts-survey-field-box-border-color: rgba(var(--rgb-blue-navy), 0.5);
    --likerts-survey-field-box-border-color-active: rgb(var(--rgb-blue-hover));
}

.c-likerts-survey-field {
    position: relative;

    // Points
    &__points {
        display: flex;
        justify-content: flex-end;
        gap: 0 var(--x-nus-20);
        margin-right: var(--x-nus-35);
        margin-bottom: 6px;
        margin-left: var(--x-nus-35);

        @media screen and (max-width: 600px){
            justify-content: space-between;
        }
    }

    &__point {
        display: flex;
        justify-content: center;
        width: 16px;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
    }

    // Subjects
    &__subjects {
        display: flex;
        gap: 10px 0;
        flex-direction: column;
    }

    &__subject-options {
        display: flex;
        align-items: center;
    }

    // Subject
    &__subject {
        padding: var(--y-nus-25) var(--x-nus-35);
        background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
    }

    &__subject-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 600px){
            flex-direction: column;
        }
    }

    &__subject-title {
        flex: 1;
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.5em;
        margin-right: var(--x-nus-20);

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }

        @media screen and (max-width: 600px){
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__subject-options {
        position: relative;
        display: flex;
        gap: 0 var(--x-nus-20);

        @media screen and (max-width: 600px){
            justify-content: space-between;
        }
    }

    &__subject-options::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        right: 0;
        height: 1px;
        background-color: rgba(var(--rgb-blue-navy), 0.3);
    }

    &__subject-label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    &__subject-input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__subject-box {
        position: relative;
        width: 16px;
        height: 16px;
        background-color: var(--likerts-survey-field-box-background-color);
        border: 1px solid var(--likerts-survey-field-box-border-color);
        border-radius: 50%;
        transition: background-color 300ms, border-color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 6px;
            height: 6px;
            background-color: var(--likerts-survey-field-dot-color);
            border-radius: 50%;
            transition: transform 300ms;
        }
    }

    // Input checked
    &__subject-input:checked ~ &__subject-box {
        background-color: var(--likerts-survey-field-box-background-color-active);
        border-color: var(--likerts-survey-field-box-border-color-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    // Erreur
    &__subject-error {
        position: relative;
    }
}
