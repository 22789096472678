.c-most-wanted-document-card {
    width: 100%;
    padding: var(--y-nus-30) var(--x-nus-40);
    background-color: rgb(var(--rgb-grey-background));

    &__surtitle {
        display: block;
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
        margin-bottom: var(--y-nus-20);
    }

    &__title {
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &__subtitle {
        display: block;
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-grey-note));
        margin-top: var(--y-nus-20);
    }
}
