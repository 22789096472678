.c-stars-survey-field {
    position: relative;
    padding: var(--y-nus-25) var(--x-nus-35);
    background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));

    &__consonances {
        display: flex;
        gap: 0 var(--x-nus-25);
        margin-bottom: 10px;
    }

    &__consonance {
        flex: 1;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
    }

    &__consonance:last-child {
        text-align: right;
    }

    &__raty-stars {
        flex: 1;
        display: flex;
        justify-content: space-between;
        gap: 0 5px;
    }

    &__raty-stars img {
        width: 100%;
        max-width: 35px;
        min-width: 0;
    }

    // Erreur
    .c-form-error {
        position: relative;
    }
}
