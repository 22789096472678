/*
|--------------------------------------------------------------------------
| Les variables de couleur
|--------------------------------------------------------------------------
|
| Déclaration des variables de couleurs du projet
|
*/

:root {
    --rgb-black: 0, 0, 0;                                           // #000000 - Noir
    --rgb-white: 255, 255, 255;                                     // #ffffff - Blanc
    --rgb-1: 51, 51, 51;                                            // #333333 - Gris très foncé
    --rgb-2: 102, 102, 102;                                         // #666666 - Gris un peu foncé
    --rgb-3: 128, 128, 128;                                         // #808080 - Gris
    --rgb-4: 153, 153, 153;                                         // #999999 - Gris un peu pâle
    --rgb-5: 179, 179, 179;                                         // #b3b3b3 - Gris pâle
    --rgb-6: 204, 204, 204;                                         // #cccccc - Gris très pâle
    --rgb-7: 230, 230, 230;                                         // #e6e6e6 - Gris pas mal pâle
    --rgb-8: 242, 242, 242;                                         // #f2f2f2 - Gris presque blanc
    --rgb-red-error: 224, 25, 44;                                   // #E0192C - Rouge erreur

    --rgb-blue-navy: 7, 52, 102;                                    // #073466 - Bleu marine
    --rgb-blue-dark-navy: 3, 37, 75;                                // #03254B - Bleu marine foncé
    --rgb-blue-hover: 21, 96, 180;                                  // #1560B4 - Bleu hover
    --rgb-blue-inoffensive: 19, 68, 123;                            // #13447B - Bleu navy + bleu hover (mix)
    --rgb-grey-background: 237, 240, 243;                           // #EDF0F3 - Gris arrière-plan
    --rgb-grey-note: 107, 125, 143;                                 // #6B7D8F - Gris note
    --rgb-grey-scrollbar-thumb: 191, 201, 210;                      // #BFC9D2 - Gris barre de défilement
    --rgb-red: 171, 19, 48;                                         // #AB1330 - Rouge
    --rgb-grey-background-with-blue-navy-alpha-10: 214, 221, 229;   // #D6DDE5
}
