.c-button-share {
    display: inline-flex;
    align-items: center;

    &__text {
        font-size: var(--button-share-text-font-size, var(--fz-13));
        font-weight: 500;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &:hover &__text {
        color: rgb(var(--rgb-blue-hover));
    }

    &__svg {
        width: 12px;
        height: 12px;
        fill: rgb(var(--rgb-blue-navy));
        transition: fill 300ms;
        margin-left: 10px;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-blue-hover));
    }
}
