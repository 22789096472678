@use "../../base/typography";

.c-buttons-repeater {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    // Button
    &__button {
        @extend .c-button;
        @extend .c-button--empty-blue-navy-alpha-30;
    }

    &__button-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background-color: rgb(var(--rgb-grey-background));
        border-radius: 50%;
        transition: background-color 300ms;
    }

    &__button:hover &__button-circle {
        background-color: rgb(var(--rgb-white));
    }

    &__button-circle-plus,
    &__button-circle-minus {
        position: relative;
        display: block;
        width: 8px;
        height: 8px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 1.5px;
            background-color: rgb(var(--rgb-blue-navy));
            border-radius: 2px;
            transition: background-color 300ms;
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }

    &__button-circle-minus::after {
        opacity: 0;
    }

    &__button:hover &__button-circle-plus,
    &__button:hover &__button-circle-minus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-blue-hover));
        }
    }
}
