.o-section {
    &--page-title {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-30);
        margin-bottom: var(--y-30);
    }

    &--cms-content {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--small-documents {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--small-document {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--documents {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--documents {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--gallery {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--gallery {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--rubrics {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--rubrics {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--form {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--form {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--jobs {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--jobs {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--component-information {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content  &--component-information {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--component-events {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--component-events {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--component-publications {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--component-publications {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--component-showcase {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--component-showcase {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--component-survey {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--component-survey {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }

    &--component-recollect {
        padding-right: var(--x-60);
        padding-left: var(--x-60);
        margin-top: var(--y-120);
        margin-bottom: var(--y-120);
    }

    &--aside-and-content &--component-recollect {
        margin-top: var(--y-65);
        margin-bottom: var(--y-65);
    }


    // Section aside and content
    &--aside-and-content {
        display: flex;

        --section-aside-width: max(calc(((310 + 60) / 1440) * 100%), (240px + var(--x-60)));
        --section-aside-padding-left: var(--x-60);
        --section-aside-margin-top: var(--y-30);
        --section-aside-margin-bottom: var(--y-120);

        --section-content-padding-left: var(--x-70);
        --section-content-padding-right: var(--x-120);
        --section-content-margin-top: var(--y-30);
        --section-content-margin-bottom: var(--y-120);

        @media screen and (max-width: 1000px) {
            --section-aside-width: 0px;
            --section-content-padding-right: var(--x-60);
        }
    }

    &--aside {
        width: var(--section-aside-width);
        padding-left: var(--section-aside-padding-left);
        margin-top: var(--section-aside-margin-top);
        margin-bottom: var(--section-aside-margin-bottom);

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }

    &--content {
        flex: 1;
        max-width: calc(100vw - var(--section-aside-width));
        padding-left: var(--section-content-padding-left);
        padding-right: var(--section-content-padding-right);
        margin-top: var(--section-content-margin-top);
        margin-bottom: var(--section-content-margin-bottom);

        & > :first-child {
            margin-top: 0;
        }

        & > :last-child {
            margin-bottom: 0;
        }

        &:has(:last-child.o-section--aside-mobile) > :nth-last-child(2) {
            @media screen and (min-width: 1001px) {
                margin-bottom: 0;
            }
        }

        & > * {
            padding-right: 0;
            padding-left: 0;
        }
    }


    // Section aside mobile
    &--aside-mobile {
        @media screen and (min-width: 1001px) {
            display: none;
        }
    }


    // Section suivant une section
    &--aside-and-content &--page-title + &:not(&--contact-infos) {
        margin-top: var(--y-60);
    }

    &--aside-and-content &--page-title:has(picture) + &:not(&--contact-infos) {
        margin-top: var(--y-65);
    }

    &--aside-and-content + & {
        margin-top: 0;
    }

    &--aside-and-content &--rubrics + &--rubrics,
    &--aside-and-content &--rubrics + &--jobs,
    &--aside-and-content &--jobs + &--rubrics {
        margin-top: var(--y-85);
    }


    // Section boxes
    &__boxes {
        & > * {
            margin-top: var(--y-120);
            margin-bottom: var(--y-120);
        }

        & > :first-child {
            margin-top: 0;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    &--aside-and-content &__boxes {
        & > * {
            margin-top: var(--y-65);
            margin-bottom: var(--y-65);
        }

        & > :first-child {
            margin-top: 0;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    &__box {
        padding: var(--y-nus-80) var(--x-nus-70);
        background-color: rgb(var(--rgb-grey-background));
    }
}
