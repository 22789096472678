.c-sort-or-filter-select-field {
    --sort-or-filter-select-field-label-color: rgb(var(--rgb-white));
    --sort-or-filter-select-field-label-color-hover: rgb(var(--rgb-white));
    --sort-or-filter-select-field-label-color-active: rgb(var(--rgb-white));
    --sort-or-filter-select-field-label-background: rgb(var(--rgb-blue-navy));
    --sort-or-filter-select-field-label-background-hover: rgb(var(--rgb-blue-hover));
    --sort-or-filter-select-field-label-background-active: rgb(var(--rgb-blue-hover));
    --sort-or-filter-select-field-label-border-bottom: transparent;
    --sort-or-filter-select-field-label-border-bottom-hover: transparent;
    --sort-or-filter-select-field-label-border-bottom-active: transparent;
    --sort-or-filter-select-field-pointer-container-background: rgb(var(--rgb-white));
    --sort-or-filter-select-field-pointer-container-background-hover: rgb(var(--rgb-white));
    --sort-or-filter-select-field-pointer-container-background-active: rgb(var(--rgb-white));
    --sort-or-filter-select-field-pointer-color: rgb(var(--rgb-blue-navy));
    --sort-or-filter-select-field-pointer-color-hover: rgb(var(--rgb-blue-navy));
    --sort-or-filter-select-field-pointer-color-active: rgb(var(--rgb-blue-navy));
    --sort-or-filter-select-field-option-color: rgb(var(--rgb-blue-navy));
    --sort-or-filter-select-field-option-color-hover: rgb(var(--rgb-blue-navy));
    --sort-or-filter-select-field-option-color-selected: rgb(var(--rgb-blue-navy));
    --sort-or-filter-select-field-option-background: rgb(var(--rgb-white));
    --sort-or-filter-select-field-option-background-hover: rgba(var(--rgb-blue-navy), 0.1);
    --sort-or-filter-select-field-option-background-selected: rgba(var(--rgb-blue-navy), 0.1);
    --sort-or-filter-select-field-scrollbar-color: rgb(var(--rgb-grey-scrollbar-thumb));
    --sort-or-filter-select-field-scrollbar-background: transparent;
}

.c-sort-or-filter-select-field {
    position: relative;
    height: 100%;

    .tail-select {
        position: relative;
        height: 100%;
        max-width: 525px;
        z-index: 1;
        transition-property: z-index;

        &:not(.active) {
            transition-delay: 300ms;
        }

        &.active {
            z-index: 2;
        }
    }

    .svg-container {
        position: absolute;
        top: 50%;
        right: var(--x-nus-40);
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23px;
        height: 23px;
        background-color: var(--sort-or-filter-select-field-pointer-container-background);
        border-radius: 50%;
        pointer-events: none;
        transition: background-color 300ms;
        z-index: 1;
    }

    .select-label:hover ~ .svg-container {
        background-color: var(--sort-or-filter-select-field-pointer-container-background-hover);
    }

    .tail-select.active .svg-container {
        background-color: var(--sort-or-filter-select-field-pointer-container-background-active)
    }

    .svg-container svg {
        width: 11px;
        height: 6px;
        stroke: var(--sort-or-filter-select-field-pointer-color);
        pointer-events: none;
        transition: transform 500ms var(--out-cubic), stroke 300ms;
    }

    .select-label:hover ~ .svg-container svg {
        stroke: var(--sort-or-filter-select-field-pointer-color-hover);
    }

    .tail-select.active .svg-container svg {
        stroke: var(--sort-or-filter-select-field-pointer-color-active);
        transform: rotate(-180deg);
    }

    .select-label {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 16px calc(var(--x-nus-40) + 38px) 16px var(--x-nus-40);
        background-color: var(--sort-or-filter-select-field-label-background);
        border-bottom: 1px solid var(--sort-or-filter-select-field-label-border-bottom);
        cursor: pointer;
        user-select: none;
        transition: background-color 300ms, border-color 300ms;
        z-index: 1;
    }

    .select-label:hover {
        background-color: var(--sort-or-filter-select-field-label-background-hover);
        border-color: var(--sort-or-filter-select-field-label-border-bottom-hover);
    }

    .tail-select.active .select-label {
        background-color: var(--sort-or-filter-select-field-label-background-active);
        border-color: var(--sort-or-filter-select-field-label-border-bottom-active);
    }

    .label-inner {
        position: relative;
        display: block;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--sort-or-filter-select-field-label-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: color 300ms;
    }

    .select-label:hover .label-inner {
        color: var(--sort-or-filter-select-field-label-color-hover);
    }

    .tail-select.active .label-inner {
        color: var(--sort-or-filter-select-field-label-color-active);
    }

    .select-dropdown {
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        background-color: rgb(var(--rgb-white));
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition-property: top, opacity, visibility;
        transition-duration: 300ms, 300ms, 0ms;
        transition-timing-function: var(--out-cubic), var(--out-cubic), ease;
        transition-delay: 0ms, 0ms, 300ms;
        z-index: -1;
    }

    .tail-select.active .select-dropdown {
        top: 100%;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition-property: top, opacity, visibility;
        transition-duration: 300ms, 300ms, 0s;
        transition-delay: 0ms, 0ms, 0s;
        transition-timing-function: var(--out-cubic);
    }

    .dropdown-inner {
        max-height: 205px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: var(--sort-or-filter-select-field-scrollbar-background);
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--sort-or-filter-select-field-scrollbar-color);
        }
    }

    .dropdown-option {
        padding: 16px var(--x-nus-40);
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--sort-or-filter-select-field-option-color);
        background-color: var(--sort-or-filter-select-field-option-background);
        cursor: pointer;
        user-select: none;
        transition: color 300ms, background-color 300ms;
    }

    .dropdown-option:hover {
        color: var(--sort-or-filter-select-field-option-color-hover);
        background-color: var(--sort-or-filter-select-field-option-background-hover);
    }

    .dropdown-option.selected {
        color: var(--sort-or-filter-select-field-option-color-selected);
        background-color: var(--sort-or-filter-select-field-option-background-selected);
    }

    // Select natif
    select {
        width: 100%;
        max-width: 525px;
        padding: 16px calc(var(--x-nus-40) + 38px) 16px var(--x-nus-40);
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--sort-or-filter-select-field-label-color);
        background-color: var(--sort-or-filter-select-field-label-background);
        border: 0;
        outline: none;
        background-image: url("../medias/images/icons/pointer-sort-or-filter-select.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - var(--x-nus-40)) 50%;
        background-size: 23px 23px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-appearance: none;

        // Permet de pouvoir scroller au select si le champs est requis et vide
        &[data-select-hidden="0"] {
            display: block !important;
            height: 0;
            padding: 0;
            margin: 0;
            opacity: 0;
        }
    }

    select::-ms-expand {
        display: none;
    }
}
