@use "../../abstract/placeholders";

:root {
    --header-top-height: 50px;
    --header-bottom-height: 100px;
    --header-bottom-margin-top: 0px;
    --header-height: calc(var(--header-top-height) + var(--header-bottom-height) + var(--header-bottom-margin-top));

    @media screen and (max-width: 600px) {
        --header-top-height: 0px;
        --header-bottom-height: 62px;
        --header-bottom-margin-top: 22px;
    }
}

.c-header {
    padding: 0 var(--x-60);

    // Top
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--header-top-height);
        padding: 0 var(--x-25);

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    // Top left - zoom font-size options list
    &__zoom-font-size-options-list-item:not(:last-child) {
        margin-right: 1px;
    }

    &__zoom-font-size-options-list {
        display: flex;
        align-items: baseline;
    }

    &__zoom-font-size-option {
        font-weight: 600;
        line-height: 1;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__zoom-font-size-options-list-item:nth-child(1) .c-header__zoom-font-size-option { font-size: 11px; }
    &__zoom-font-size-options-list-item:nth-child(2) .c-header__zoom-font-size-option { font-size: 14px; }
    &__zoom-font-size-options-list-item:nth-child(3) .c-header__zoom-font-size-option { font-size: 18px; }

    &__zoom-font-size-option:hover,
    &__zoom-font-size-option.is-active {
        color: rgb(var(--rgb-blue-hover));
    }

    // Top right - main navigations list
    &__secondary-navigations-list {
        display: flex;
        align-items: center;
    }

    &__secondary-navigations-list-item:not(:last-child) {
        margin-right: var(--x-30);
    }

    &__secondary-navigation {
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__secondary-navigation:hover {
        color: rgb(var(--rgb-blue-hover));
    }

    // Bottom
    &__bottom {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--header-bottom-height);
        padding: 0 var(--x-25);
        background-color: rgb(var(--rgb-grey-background));
        margin-top: var(--header-bottom-margin-top);
        z-index: 115;

        @media screen and (max-width: 600px) {
            padding: 0 10px;
        }
    }

    // Bottom left - logo
    &__logo-link {
        display: block;
        width: 100%;
        max-width: 180px;

        @media screen and (max-width: 600px) {
            max-width: 150px;
        }
    }

    &__logo-img {
        max-width: 100%;
        vertical-align: bottom;
    }

    // Bottom right
    &__bottom-right {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: var(--x-50);
    }

    // Bottom right - main navigations list
    &__main-navigations-list-element {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: var(--x-25);

        @media screen and (max-width: 1300px) {
            display: none;
        }
    }

    &__main-navigations-list {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__main-navigations-list-item {
        height: 100%;
    }

    &__main-navigations-list-item:not(:last-child) {
        margin-right: var(--x-30);
    }

    &__main-navigation {
        position: relative;
        height: 100%;
        font-size: var(--fz-16);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 30px;
            left: 0;
            height: 5px;
            background: rgb(var(--rgb-blue-hover));
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    &__main-navigation:hover {
        color: rgb(var(--rgb-blue-hover));
    }

    // Bottom right - button menu
    &__button-menu-element {
        margin-left: 15px;

        @media screen and (min-width: 1301px) {
            display: none;
        }

        @media screen and (max-width: 600px) {
            margin-left: 8px;
        }
    }

    &__button-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        transition: background-color 300ms;

        @media screen and (max-width: 600px) {
            width: 40px;
            height: 40px;
        }
    }

    &__button-menu:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__button-menu-lines {
        position: relative;
        width: 18px;
        height: 15px;

        @media screen and (max-width: 600px) {
            width: 16px;
            height: 13px;
        }
    }

    &__button-menu-line {
        position: absolute;
        left: 0%;
        display: block;
        width: 100%;
        height: 2px;
        background-color: rgb(var(--rgb-white));
        border-radius: 2px;
        transition: all 300ms;
    }

    &__button-menu-line--line-1 {
        top: 0px;
    }

    &__button-menu-line--line-2,
    &__button-menu-line--line-3 {
        top: calc(50% - 1px);
    }

    &__button-menu-line--line-4 {
        bottom: 0px;
    }

    // Bottom right - button search
    &__button-search {
        position: relative;
        width: 50px;
        height: 50px;
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        transition: background-color 300ms;

        @media screen and (max-width: 600px) {
            width: 40px;
            height: 40px;
        }
    }

    &__button-search:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__button-search-open,
    &__button-search-close {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 300ms;
    }

    &__button-search-close {
        opacity: 0;
    }

    &__button-search-open-svg {
        display: block;
        width: 17px;
        height: 17px;
        stroke: rgb(var(--rgb-white));

        @media screen and (max-width: 600px) {
            width: 14px;
            height: 14px;
        }
    }

    &__button-search-close-x {
        display: block;
        @extend %x;
        --x-size: 18px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-white));
        --x-border-radius: 2px;

        @media screen and (max-width: 600px) {
            width: 15px;
            height: 15px;
        }
    }
}
