@use "../../../base/typography";

.c-boolean-survey-field {
    --boolean-survey-field-dot-color: rgb(var(--rgb-white));
    --boolean-survey-field-box-background-color: rgb(var(--rgb-white));
    --boolean-survey-field-box-background-color-active: rgb(var(--rgb-blue-hover));
    --boolean-survey-field-box-border-color: rgba(var(--rgb-blue-navy), 0.5);
    --boolean-survey-field-box-border-color-active: rgb(var(--rgb-blue-hover));
}

.c-boolean-survey-field {
    position: relative;

    &__options {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
    }

    &__label {
        display: flex;
        align-items: center;
        padding: var(--y-nus-25) var(--x-nus-35) var(--y-nus-25) var(--x-nus-25);
        background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
        cursor: pointer;
    }

    &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__box {
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: var(--x-nus-20);
        background-color: var(--boolean-survey-field-box-background-color);
        border: 1px solid var(--boolean-survey-field-box-border-color);
        border-radius: 50%;
        transition: background-color 300ms, border-color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 6px;
            height: 6px;
            background-color: var(--boolean-survey-field-dot-color);
            border-radius: 50%;
            transition: transform 300ms;
        }
    }

    // Input checked
    &__input:checked ~ &__box {
        background-color: var(--boolean-survey-field-box-background-color-active);
        border-color: var(--boolean-survey-field-box-border-color-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &__text {
        flex: 1;
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.5em;
        transition: color 300ms;

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }
    }

    &__label:hover &__text {
        color: rgb(var(--rgb-blue-hover));
    }

    // Erreur
    &__error {
        position: relative;
    }
}
