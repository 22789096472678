@use "../../base/typography";

.c-section-footer {
    display: flex;
    justify-content: center;
    margin-top: var(--y-50);

    &__button {
        @extend .c-button;
        @extend .c-button--full-blue-navy;

        @media screen and (max-width: 500px) {
            justify-content: center;
            width: 100%;
        }
    }
}
