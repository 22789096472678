.c-rubric-card {
    border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    -webkit-tap-highlight-color: transparent;

    &:first-child {
        border-top: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 138px;
        padding: 20px 0;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
            min-height: 120px;
        }
        @media screen and (max-width: 768px)  {
            min-height: 100px;
        }
    }

    &__picture {
        flex-shrink: 0;
        width: 130px;
        height: 100px;
        margin-right: 30px;
        overflow: hidden;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__text {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 5px;
    }

    &__title {
        font-size: var(--fz-22);
        font-weight: 600;
        line-height: 1.5em;
        transition: color 300ms;
    }

    &__header:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &.js-accordion-opened &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &__plus-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: rgb(var(--rgb-grey-background));
        border-radius: 50%;
        transition: background-color 300ms;
    }

    &__header:hover &__plus-container  {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &.js-accordion-opened &__plus-container {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__plus {
        position: relative;
        width: 13px;
        height: 13px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 2px;
            background-color: rgb(var(--rgb-blue-navy));
            border-radius: 2px;
            opacity: 1;
            transition: background-color 300ms, opacity 300ms;
            will-change: transform;
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }

    &__header:hover &__plus::before,
    &__header:hover &__plus::after {
        background-color: rgb(var(--rgb-white));
    }

    &.js-accordion-opened &__plus::before,
    &.js-accordion-opened &__plus::after {
        background-color: rgb(var(--rgb-white));
    }

    &.js-accordion-opened &__plus::after {
        opacity: 0;
    }

    &__content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    &__transition {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms var(--in-out-quart), transform 700ms var(--in-out-quart);
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }

    &__transition > :first-child {
        margin-top: 0;
    }

    &__button-map,
    &__cms-content,
    &__documents,
    &__gallery,
    &__organizations {
        padding-bottom: var(--y-60);
    }

    &__button-share {
        --button-share-text-font-size: var(--fz-15);
        padding-bottom: 25px;
    }

    &:not(.js-accordion-opened) {
        iframe {
            pointer-events: none;
        }
    }
}
