.c-side-navigation {
    // Level 1
    &__level-1-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: var(--y-nus-25) var(--x-nus-30);
        background-color: rgb(var(--rgb-blue-navy));
    }

    &__level-1:not(:last-child) &__level-1-link {
        border-bottom: 1px solid rgba(var(--rgb-white), 0.1);
    }

    &--in-aside &__level-1-link {
        pointer-events: none;
    }

    &__level-1-text {
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        text-align: left;
        transition: color 300ms;
    }

    &__level-1-link:hover &__level-1-text {
        color: rgba(var(--rgb-white), 0.6);
    }

    &__level-1-plus {
        position: relative;
        display: block;
        width: 10px;
        height: 10px;
        margin-left: 20px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 1.5px;
            background-color: rgb(var(--rgb-white));
            border-radius: 2px;
            opacity: 1;
            transition: background-color 300ms, opacity 300ms;
            will-change: transform;
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }

    &__level-1-link:hover &__level-1-plus::after,
    &__level-1-link:hover &__level-1-plus::before {
        background-color: rgba(var(--rgb-white), 0.6);
    }

    &__level-1-item.js-accordion-opened &__level-1-plus::after {
        opacity: 0;
    }

    &__level-1-content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    &--in-aside &__level-1-content {
        height: 100%;
    }

    // Level 2
    &__level-2-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: var(--y-nus-25) var(--x-nus-30);
        background-color: rgb(var(--rgb-grey-background));
    }

    &__level-2:not(:last-child) &__level-2-link {
        border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.1);
    }

    &__level-2-link.is-active {
        pointer-events: none;
    }

    &__level-2-text {
        flex: 1;
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-grey-note));
        text-align: left;
        transition: color 300ms;
    }

    &__level-2-link:hover &__level-2-text,
    &__level-2-item.js-accordion-opened &__level-2-link:hover &__level-2-text {
        color: rgb(var(--rgb-blue-hover));
    }

    &__level-2-item.js-accordion-opened &__level-2-text {
        color: rgb(var(--rgb-blue-navy));
    }

    &__level-2-link.is-active &__level-2-text {
        color: rgb(var(--rgb-blue-navy));
    }

    &__level-2-plus {
        position: relative;
        display: block;
        width: 10px;
        height: 10px;
        margin-left: 20px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 1.5px;
            background-color: rgb(var(--rgb-blue-navy));
            border-radius: 2px;
            opacity: 1;
            transition: background-color 300ms, opacity 300ms;
            will-change: transform;
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }

    &__level-2-link:hover &__level-2-plus::before,
    &__level-2-link:hover &__level-2-plus::after {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__level-2-item.js-accordion-opened &__level-2-plus::after {
        opacity: 0;
    }

    &__level-2-content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    // Level 3
    &__level-3 {
        padding: 10px 0 var(--y-nus-30);
        background-color: rgb(246, 248, 249); // #F6F8F9
    }

    &__level-3-link {
        position: relative;
        display: block;
        padding: 10px var(--x-nus-30);
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: font-weight 300ms, color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 5px;
            background: rgb(var(--rgb-red));
            opacity: 0;
            transition: opacity 300ms;
        }

        &::after {
            content: "";
            position: absolute;
            right: var(--x-nus-30);
            bottom: 0;
            left: var(--x-nus-30);
            height: 1px;
            background-color: rgba(var(--rgb-blue-navy), 0.1);
        }
    }

    &__level-3-link:hover {
        color: rgb(var(--rgb-blue-hover));
    }

    &__level-3-link.is-active {
        font-weight: 600;
        pointer-events: none;

        &::before {
            opacity: 1;
        }
    }
}
