@use "../../abstract/placeholders";
@use "../../base/typography";

.c-overlay-share {
    --overlay-share-width: 520px;
    --overlay-share-padding: 80px 22px;
    --overlay-share-links-gap-x: 20px;

    @media screen and (max-width: 1200px) {
        --overlay-share-width: 490px;
    }

    @media screen and (max-width: 1024px) {
        --overlay-share-width: 400px;
        --overlay-share-padding: 50px 22px;
    }

    @media screen and (max-width: 400px) {
        --overlay-share-links-gap-x: 15px;
    }
}

.c-overlay-share {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 120;

    // Container
    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--overlay-share-width);
        max-width: calc(100% - (var(--x-40) * 2));
        max-height: calc(var(--vh, 1vh) * 90);
        padding: var(--overlay-share-padding);
        background-color: rgb(var(--rgb-white));
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: transform 800ms var(--in-out-quart), opacity 800ms var(--in-out-quart);
    }

    &__title {
        @extend .c-medium-title;
        text-align: center;
        margin-bottom: 30px;
    }

    &__button-close {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
    }

    &__button-close-x {
        @extend %x;
        --x-size: 15px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-blue-navy));
        --x-border-radius: 2px;
    }

    &__button-close:hover &__button-close-x {
        --x-color: rgb(var(--rgb-blue-hover));
    }

    &__links {
        margin: 0 calc(var(--overlay-share-links-gap-x) / -2);
        display: flex;
        justify-content: center;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        background: rgb(var(--rgb-grey-background));
        border-radius: 50%;
        transition: background 300ms;
        margin: 0 calc(var(--overlay-share-links-gap-x) / 2);
    }

    &__link:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__link-svg {
        width: 20px;
        height: 20px;
        fill: rgb(var(--rgb-blue-navy));
        transition: fill 300ms;
    }

    &__link:hover &__link-svg {
        fill: rgb(var(--rgb-white));
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 500ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-share {
    .c-overlay-share {
        visibility: visible;
        pointer-events: all;

        &__container {
            opacity: 1;
            transform: translate(-50%, -50%);
            pointer-events: all;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.25);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-share.closing-overlay-share {
    .c-overlay-share {
        &__container {
            opacity: 0;
            transform: translate(-50%, -40%);
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}
