.c-component-publications-swiper {
    &__swiper {
        position: relative;
    }

    &__swiper-navigation {
        position: absolute;
        top: 25%;
        right: 0;
        transform: translateY(-50%);
        display: flex;
        z-index: 2;

        &-button-prev,
        &-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: rgb(var(--rgb-blue-navy));
            border-radius: 50%;
            transition: background-color 300ms;
        }

        &-button-prev {
            margin-right: 10px;
        }

        &-button-prev:hover,
        &-button-next:hover {
            background-color: rgb(var(--rgb-blue-hover));
        }

        &-button-prev.is-disabled,
        &-button-next.is-disabled {
            pointer-events: none;
        }

        &-button-svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-white));
            transition: stroke 300ms;
        }

        &-button-prev &-button-svg {
            transform: rotate(180deg);
        }

        &-button-prev.is-disabled &-button-svg,
        &-button-next.is-disabled &-button-svg {
            stroke: rgba(var(--rgb-white), 0.5);
        }
    }

    &__swiper-container {
        margin-right: calc(0px - var(--x-60));
    }

    &--in-aside-and-content &__swiper-container {
        margin-right: calc(0px - var(--section-content-padding-right));
    }

    .swiper {
        padding-right: var(--x-60) !important;

        @media screen and (max-width: 500px) {
            padding-right: calc(var(--x-60) + 40px) !important;
        }

        @media screen and (max-width: 400px) {
            padding-right: calc(var(--x-60) + 20px) !important;
        }
    }

    &--in-aside-and-content .swiper {
        padding-right: var(--section-content-padding-right) !important;

        @media screen and (max-width: 500px) {
            padding-right: calc(var(--section-content-padding-right) + 40px) !important;
        }

        @media screen and (max-width: 400px) {
            padding-right: calc(var(--section-content-padding-right) + 20px) !important;
        }
    }

    .swiper-slide {
        width: calc(33.33% - (var(--x-30) / 2)) !important;
        height: auto !important;

        @media screen and (max-width: 1200px) {
            width: calc(50% - (var(--x-30) / 2)) !important;
        }

        @media screen and (max-width: 500px) {
            width: 100% !important;
        }
    }

    &--in-aside-and-content .swiper-slide {
        width: calc(50% - (var(--x-30) / 2)) !important;

        @media screen and (max-width: 500px) {
            width: 100% !important;
        }
    }
}
