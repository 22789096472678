@use "../../base/typography";

.c-component-survey {
    display: flex;
    gap: var(--y-nus-50) var(--x-nus-80);
    padding: var(--y-nus-75) var(--x-70);
    background-color: rgb(var(--rgb-grey-background));

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }

    &--in-aside-and-content {
        flex-direction: column;
    }

    &__infos {
        flex: 1 // 500/1180
    }

    &__form {
        width: 50.8447%; // 600/1180

        @media screen and (max-width: 1000px) {
            width: 100%;
        }
    }

    &--in-aside-and-content &__form {
        width: 100%;
    }

    &__title {
        font-size: var(--fz-45);
        font-weight: 600;
        line-height: 1.3em;

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }

        &.u-font-size-40 { font-size: var(--fz-40); }
        &.u-font-size-35 { font-size: var(--fz-35); }
        &.u-font-size-30 { font-size: var(--fz-30); }
    }

    &__description {
        margin-top: var(--y-nus-30);
    }

    &__note {
        @extend .c-note;
        display: block;
        margin-top: var(--y-nus-30);

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }

        p {
            display: inline;
        }
    }

    &__button-submit {
        @extend .c-button;
        @extend .c-button--full-blue-navy;
        margin-top: var(--y-nus-30);
    }
}
