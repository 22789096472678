@use "../../abstract/placeholders";

.c-filter-swiper {
    display: flex;
    align-items: center;
    padding: var(--y-nus-23) var(--x-nus-40);
    background-color: rgb(var(--rgb-grey-background));

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: normal;
    }

    &__title {
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-right: var(--x-nus-30);

        @media screen and (max-width: 600px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__container {
        flex: 1;
        position: relative;
        min-width: 0;
    }

    .swiper-slide {
        width: auto !important;
    }

    &__option {
        @extend %tag;
        --tag-background-color: transparent;
        --tag-font-weight: 500;
    }

    &__option--active {
        --tag-background-color: rgb(var(--rgb-blue-hover));
        --tag-color: rgb(var(--rgb-white));
    }

    &__option:not(&__option--active):hover {
        color: rgb(var(--rgb-blue-hover));
    }

    &__button-swiper-navigation-of-filter {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
    }
}
