.c-button-swiper-navigation-of-filter {
    --button-swiper-navigation-of-filter-size: 23px;
}

.c-button-swiper-navigation-of-filter {
    &__prev-container,
    &__next-container {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
    }

    &__prev-container {
        left: 0;
    }

    &__next-container {
        right: 0;
    }

    &__prev,
    &__next {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--button-swiper-navigation-of-filter-size);
        height: var(--button-swiper-navigation-of-filter-size);
        background-color: rgb(var(--rgb-blue-navy));
        border-radius: 50%;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: background-color 300ms, opacity 300ms, visibility 300ms;
        z-index: 2;
    }

    &__prev:hover,
    &__next:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__prev.is-disabled,
    &__next.is-disabled {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    &__prev-svg,
    &__next-svg {
        width: 11px;
        height: 6px;
        transform: rotate(270deg);
        stroke: rgb(var(--rgb-white));
        transition: stroke 300ms;
    }

    &__prev-svg {
        transform: rotate(90deg);
    }

    // Background degrade
    &__prev-background,
    &__next-background {
        position: absolute;
        top: 0;
        bottom: 0;
        width: calc(var(--button-swiper-navigation-of-filter-size) + 5px);
        background-color: rgb(var(--rgb-grey-background));
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms, visibility 30ms;
        z-index: 1;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 40px;
        }
    }

    &__prev-background {
        left: 0;

        &::after {
            left: calc(var(--button-swiper-navigation-of-filter-size) + 5px);
            background: linear-gradient(90deg, rgba(var(--rgb-grey-background), 1) 0%, rgba(var(--rgb-grey-background), 0) 100%);
        }
    }

    &__next-background {
        right: 0;

        &::after {
            right: calc(var(--button-swiper-navigation-of-filter-size) + 5px);
            background: linear-gradient(-90deg, rgba(var(--rgb-grey-background), 1) 0%, rgba(var(--rgb-grey-background), 0) 100%);
        }
    }

    &__prev.is-disabled + &__prev-background,
    &__next.is-disabled + &__next-background {
        opacity: 0;
        visibility: hidden;
    }
}
