.c-thumbnail-survey-field {
    position: relative;

    &__options {
        display: flex;
        flex-wrap: wrap;
        gap: var(--y-nus-20) var(--x-nus-20);
    }

    &__label {
        position: relative;
        cursor: pointer;
    }

    // Input disabled
    &__label--disabled {
        pointer-events: none;
    }

    &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__circle {
        position: absolute;
        right: 8px;
        bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background-color: rgb(var(--rgb-white));
        border-radius: 50%;
        opacity: 0;
        transition: opacity 300ms;
        z-index: 2;
    }

    // Input checked
    &__input:checked ~ &__circle {
        opacity: 1;
    }

    &__circle-svg {
        width: 12px;
        height: 10px;
        stroke: rgb(var(--rgb-blue-hover));
    }

    &__picture {
        position: relative;
        overflow: hidden;
    }

    &__label--disabled &__picture {
        opacity: 0.5;
    }

    &__picture::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgb(var(--rgb-blue-hover), 0.3);
        opacity: 0;
        transition: opacity 300ms;
        z-index: 1;
    }

    &__label:hover &__picture::before {
        opacity: 1;
    }

    &__picture::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(310deg, rgba(var(--rgb-blue-navy), 0.5) 0%, rgba(var(--rgb-blue-navy), 0) 100%);
    }

    &__img {
        max-height: 135px;
        width: 100%;

        @media screen and (max-width: 1000px) {
            max-height: 100px;
        }
    }

    // Erreur
    &__error {
        position: relative;
    }
}
