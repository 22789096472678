@use "../../abstract/placeholders";

.c-overlay-organization {
    --overlay-organization-width: 410px;
    --overlay-organization-right-hide: calc(0px - var(--overlay-organization-width));
    --overlay-organization-right-show: 0px;
    --overlay-organization-button-close-size: 50px;
    --overlay-organization-image-ratio: 76.1290%; // 236/310

    @media screen and (max-width: 600px) {
        --overlay-organization-width: 100%;
        --overlay-organization-right-hide: -100%;
        --overlay-organization-button-close-size: 40px;
    }
}

.c-overlay-organization {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 110;

    &__container {
        position: absolute;
        right: var(--overlay-organization-right-hide);
        bottom: 0;
        width: var(--overlay-organization-width);
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgb(var(--rgb-white));
        overflow: hidden;
        transition: right 800ms var(--in-out-quart);
    }

    // Button close
    &__button-close {
        position: absolute;
        top: var(--y-nus-30);
        right: var(--x-nus-30);
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--overlay-organization-button-close-size);
        height: var(--overlay-organization-button-close-size);
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        transition: background-color 300ms;
        z-index: 2;
    }

    &__button-close:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__button-close-x {
        display: block;
        @extend %x;
        --x-size: 18px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-white));
        --x-border-radius: 2px;

        @media screen and (max-width: 600px) {
            --x-size: 15px;
        }
    }

    // Content
    &__content {
        max-height: 100%;
        padding: calc(var(--overlay-organization-button-close-size) + var(--y-nus-30)) var(--x-nus-50) var(--y-nus-30);

        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-white));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-grey-scrollbar-thumb));
        }
    }

    // Content transition
    &__content-transition {
        opacity: 0;
        transition: opacity 600ms 0ms;
    }

    // Espacements
    &__content-transition > *:not(:last-child) {
        margin-bottom: var(--y-nus-25);
    }

    // Content general
    &__category {
        @extend %tag;
    }

    &__title {
        display: block;
        font-size: var(--fz-21);
        font-weight: 500;
        line-height: 1.5em;
    }

    &__sub-category-and-button-share {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__sub-category {
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-grey-note));
    }

    &__button-share {
        .c-button-share__text {
            font-size: var(--fz-13);
            font-weight: 500;
            line-height: 1.5em;
        }
    }

    &__separator {
        border: 0;
        border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.3);
        margin-top: 0;
    }

    &__picture {
        position: relative;
        padding-bottom: var(--overlay-organization-image-ratio);
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__surtitle {
        font-size: var(--fz-15);
        line-height: 1.6em;
        font-weight: 600;
    }

    &__info-svg {
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        margin-right: 15px;
        transition: fill 300ms, stroke 300ms;
    }

    &__info-svg--fill {
        fill: rgb(var(--rgb-blue-navy));
    }

    &__info:hover &__info-svg--fill {
        fill: rgb(var(--rgb-blue-hover));
    }

    &__info-svg--stroke {
        stroke: rgb(var(--rgb-blue-navy));
    }

    &__info:hover &__info-svg--stroke {
        stroke: rgb(var(--rgb-blue-hover));
    }

    &__info {
        display: flex;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    }

    &__infos-list-item:first-child &__info {
        border-top: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    }

    &__info-text {
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__info:hover &__info-text {
        color: rgb(var(--rgb-blue-hover));
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 500ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-organization {
    .c-overlay-organization {
        visibility: visible;
        pointer-events: all;

        &__container {
            right: var(--overlay-organization-right-show);
        }

        &__content-transition {
            opacity: 1;
            transition: opacity 600ms 300ms;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.25);
        }
    }

    .c-header__bottom {
        z-index: 105;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-organization.closing-overlay-organization {
    .c-overlay-organization {
        &__container {
            right: var(--overlay-organization-right-hide);
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0);
            transition-delay: 300ms;
        }
    }

    .c-header__bottom {
        z-index: 105;
    }
}
