/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract/mixins" as *;

:root {
    // Générer les variables CSS des font-sizes
    @include fontSizes(
        "13", "14", "15", "16", "17", "18", "20", "21", "22", "25", "26", "28", "30", "35", "39", "40", "45", "50", "55", "70",
    );

    // Générer les variables CSS des espacements
    @include spacings(
        "x-20", "x-25", "x-30", "x-35", "x-40", "x-45", "x-50", "x-60", "x-70", "x-75", "x-80", "x-85", "x-100", "x-120", "x-122", "x-180", "x-190",
        "y-20", "y-23", "y-25", "y-28", "y-30", "y-35", "y-40", "y-45", "y-50", "y-55", "y-60", "y-65", "y-70", "y-75", "y-80", "y-85", "y-90", "y-100", "y-120", "y-130",
    );

    // Variables CSS
    --paragraph-font-size: var(--fz-16);
    --paragraph-line-height: 1.7em;
}
