.c-form-label {
    display: block;
    font-size: var(--fz-17);
    font-weight: 500;
    line-height: 1.5em;
    margin-bottom: 10px;

    &--radio-and-checkbox {
        display: block;
        font-family: var(--ff);
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: 5px;
    }
}

.c-form-required-dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: rgb(var(--rgb-red-error));
    border-radius: 50%;
    vertical-align: super;
    margin-bottom: 0.2em;
    margin-left: 2px;
}
