.c-overlay-menu-mobile {
    position: fixed;
    top: var(--alerts-height);
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    transition: top 500ms var(--in-out-cubic);
    z-index: 110;

    &__container {
        position: relative;
        top: var(--header-top-height);
        left: var(--x-60);
        height: 0px;
        max-height: calc((var(--vh, 1vh) * 100) - var(--header-top-height) - var(--header-top-height));
        width: calc(100% - var(--x-60) - var(--x-60));
        background-color: rgb(var(--rgb-white));
        overflow: hidden;
        transition: height 800ms var(--in-out-quart), box-shadow 300ms;

        @media screen and (max-width: 600px) {
            top: 0;
            left: 0;
            width: 100%;

            // Note : Le before est utilisé pour voir la scrollbar de 5px à droite lorsqu'il y a beaucoup de contenu
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 5px;
                left: 0;
                height: var(--header-height);
                background-color: rgb(var(--rgb-white));
                z-index: 2;
            }
        }
    }

    // Contents
    &__contents {
        position: relative;
        padding-top: var(--header-bottom-height);
        opacity: 0;
        transition: opacity 600ms 0ms;

        @media screen and (max-width: 600px) {
            padding-top: 0;
        }
    }

    // Contents scroll
    &__contents-scroll {
        max-height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--header-top-height));
        padding: 0 var(--x-25);

        // Note : Le overflow-y est à scroll, car sinon il un saut de 5px sur l'horizontal
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-white));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-grey-scrollbar-thumb));
        }

        @media screen and (max-width: 600px) {
            max-height: calc((var(--vh, 1vh) * 100));
            padding: var(--header-height) var(--x-60) 0;
        }
    }

    // Content - Side navigation
    &__content-side-navigation {
        padding-top: var(--y-nus-30);
    }

    // Content - Secondary navigation
    &__content-secondary-navigation {
        padding-top: var(--y-nus-35);
        padding-bottom: var(--y-nus-30);
    }

    &__secondary-navigations-list-item:not(:last-child) {
        margin-bottom: var(--y-nus-20);
    }

    &__secondary-navigation {
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__secondary-navigation:hover {
        color: rgb(var(--rgb-blue-hover));
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-menu-mobile {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-menu-mobile {
        visibility: visible;
        pointer-events: all;

        &__container {
            height: calc((var(--vh, 1vh) * 100) - var(--header-top-height) - var(--header-top-height));
            box-shadow: 0 57px 70px rgba(4, 22, 43, 0.4);
        }

        &__contents {
            opacity: 1;
            transition: opacity 600ms 300ms;
        }
    }

    .c-header__button-menu {
        background-color: rgb(var(--rgb-blue-hover));

        &-line--line-1 { width: 0%; top: calc(50% - 1px); left: 50%; opacity: 0; }
        &-line--line-2 { transform: rotate(45deg); width: 100%; }
        &-line--line-3 { transform: rotate(-45deg); width: 100%; }
        &-line--line-4 { width: 0%; bottom: calc(50% - 1px); left: 50%; opacity: 0; }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu-mobile.closing-overlay-menu-mobile {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-menu-mobile {
        &__container {
            height: 0px;
            box-shadow: 0 57px 70px rgba(4, 22, 43, 0);
            transition: height 800ms var(--in-out-quart), box-shadow 300ms 500ms;
        }

        &__contents {
            opacity: 1;
        }
    }
}
