#page-map {
    .c-interactiveMap {
        --font-family-map: var(--ff);
        --primary-color-map: var(--rgb-blue-navy);
        --secondary-color-map: var(--rgb-blue-navy);
        --text-color-map: var(--rgb-blue-navy);
    }

    .c-interactiveMap__logo-container {
        margin-top: var(--y-nus-55);
        margin-bottom: var(--y-nus-45);
    }

    .c-interactiveMap__logo-link {
        display: block;
        width: fit-content;
    }

    .c-interactiveMap__logo {
        width: 180px;
        max-width: 100%;
    }

    .c-map-categories {
        --lines-color: rgba(var(--rgb-blue-navy), 0.3);
    }

    .c-map-pin {
        --bg-color: #888888;
        --bg-color-hover: #616F7E;
        --bg-color-active: rgb(var(--rgb-blue-navy));
    }

    .c-map-reset-button::after {
        border-radius: 0;
    }

    .c-map-popup__inner {
        border-radius: 0;
    }

    .c-map-popup__category {
        color: rgba(var(--rgb-blue-navy), 0.6);
    }

    .c-map-close-button::after {
        background-color: rgb(var(--rgb-blue-navy));
    }

    .c-map-location-button,
    .c-map-related {
        border-color: rgba(var(--rgb-blue-navy), 0.3);
    }

    .c-map-mobile-header__logo-link {
        display: block;
        width: fit-content;
    }

    .c-map-mobile-header__logo {
        width: 95px;
        height: auto;
        max-width: 100%;
    }

    .c-map-button {
        --button-border-color: rgb(var(--rgb-blue-navy));
        --button-background-color: rgb(var(--rgb-blue-navy));
        border-radius: 0;
    }

    .c-map-button--secondary {
        --button-color: rgb(var(--rgb-blue-navy));
        --button-border-color: rgba(var(--rgb-blue-navy), 0.3);
        --button-background-color: transparent;
    }

    .c-map-button--secondary[disabled] {
        --button-color: rgba(var(--rgb-blue-navy), 0.4);
    }

    // Ajout supplémentaire
    .c-map-location-detail__dates:not(:last-child),
    .c-map-location-detail__items-on-sale:not(:last-child) {
        margin-bottom: var(--y-30-map);
    }

    .c-map-cookies-button {
        --background-color: #fff;
        --background-color-hover: rgb(var(--rgb-blue-navy));
        --icon-color: rgb(var(--rgb-blue-navy));
    }
}
