@use "../../base/typography";

.c-home-dates-to-remember {
    padding: var(--y-nus-35) var(--x-nus-70);
    background-color: rgb(var(--rgb-blue-navy));
    overflow: hidden;

    // Header
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--y-nus-20);
    }

    // Header left - title
    &__title {
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    // Header right - link all
    &__header-right {
        display: flex;
        align-items: center;

        @media screen and (max-width: 800px) {
            display: none;
        }
    }

    &__link-all {
        @extend .c-underlined-link-white;
    }

    // Header right - swiper-navigation
    &__swiper-navigation-element {
        margin-left: var(--x-nus-30);
    }

    &__swiper-navigation {
        display: flex;

        &-button-prev,
        &-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: rgb(var(--rgb-grey-background));
            border-radius: 50%;
            transition: background-color 300ms;
        }

        &-button-prev {
            margin-right: 10px;
        }

        &-button-prev:hover,
        &-button-next:hover {
            background-color: rgb(var(--rgb-blue-hover));
        }

        &-button-prev.is-disabled,
        &-button-next.is-disabled {
            pointer-events: none;
        }

        &-button-svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-blue-navy));
            transition: stroke 300ms;
        }

        &-button-prev &-button-svg {
            transform: rotate(180deg);
        }

        &-button-prev:hover &-button-svg,
        &-button-next:hover &-button-svg {
            stroke: rgb(var(--rgb-white));
        }

        &-button-prev.is-disabled &-button-svg,
        &-button-next.is-disabled &-button-svg {
            stroke: rgba(var(--rgb-blue-navy), 0.5);
        }
    }

    // Swiper
    &__swiper {
        .swiper-slide {
            width: 290px !important;
            height: auto !important;

            @media screen and (max-width: 800px) {
                width: 260px !important;
            }

            @media screen and (max-width: 600px) {
                width: 225px !important;
            }
        }
    }

    // Footer
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: var(--y-nus-20);

        @media screen and (min-width: 801px) {
            display: none;
        }
    }
}
