@use "../../abstract/placeholders";

.c-next-publication {
    &__header {
        padding: var(--y-nus-25) var(--x-nus-30);
        background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
    }

    &__title {
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
    }

    &__content {
        display: block;
        padding: var(--y-nus-40) var(--x-nus-30);
        background-color: rgb(var(--rgb-grey-background));
    }

    &__publication-category {
        @extend %tag;
        margin-bottom: var(--y-nus-25);
    }

    &__publication-title {
        display: block;
        font-size: var(--fz-15);
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
        margin-bottom: var(--y-nus-25);
    }

    &__content:hover &__publication-title {
        color: rgb(var(--rgb-blue-hover));
    }

    &__publication-date {
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
    }
}
