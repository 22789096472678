@use "../../../base/typography";

.c-likert-survey-field {
    --likert-survey-field-dot-color: rgb(var(--rgb-white));
    --likert-survey-field-box-background-color: rgb(var(--rgb-white));
    --likert-survey-field-box-background-color-active: rgb(var(--rgb-blue-hover));
    --likert-survey-field-box-border-color: rgba(var(--rgb-blue-navy), 0.5);
    --likert-survey-field-box-border-color-active: rgb(var(--rgb-blue-hover));
}

.c-likert-survey-field {
    position: relative;
    padding: var(--y-nus-40) var(--x-nus-50);
    background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));

    &__points {
        display: flex;
        margin-bottom: 10px;
    }

    &__point {
        display: flex;
        justify-content: center;
        width: 16px;
        text-align: center;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        margin: 0 auto;
    }

    &__point:first-child {
        text-align: left;
        margin: 0 auto 0 0;
    }

    &__point:last-child {
        text-align: right;
        margin: 0 0 0 auto;
    }

    &__options {
        position: relative;
        display: flex;
        margin-bottom: var(--y-nus-20);
    }

    &__options::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        right: 0;
        height: 1px;
        background-color: rgba(var(--rgb-blue-navy), 0.3);
    }

    &__label {
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto;
    }

    &__label:first-child {
        margin: 0 auto 0 0;
    }

    &__label:last-child {
        margin: 0 0 0 auto;
    }

    &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__box {
        position: relative;
        width: 16px;
        height: 16px;
        background-color: var(--likert-survey-field-box-background-color);
        border: 1px solid var(--likert-survey-field-box-border-color);
        border-radius: 50%;
        transition: background-color 300ms, border-color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 6px;
            height: 6px;
            background-color: var(--likert-survey-field-dot-color);
            border-radius: 50%;
            transition: transform 300ms;
        }
    }

    // Input checked
    &__input:checked ~ &__box {
        background-color: var(--likert-survey-field-box-background-color-active);
        border-color: var(--likert-survey-field-box-border-color-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &__consonances {
        display: flex;
    }

    &__consonance-text {
        flex: 1;
    }

    &__consonance-text {
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        text-align: center;

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }
    }

    &__consonance-text:first-child {
        text-align: left;
        margin-right: var(--x-nus-25);
    }

    &__consonance-text:last-child {
        text-align: right;
        margin-left: var(--x-nus-25);
    }

    // Erreur
    &__error {
        position: relative;
    }
}
