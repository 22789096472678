@use "../../../base/typography";

.c-slider-survey-field {
    position: relative;
    padding: var(--y-nus-40) var(--x-nus-50);
    background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));

    &__consonances {
        display: flex;
        margin-bottom: var(--y-nus-20);
    }

    &__consonance {
        flex: 1;
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        text-align: center;

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }
    }

    &__consonance:first-child {
        text-align: left;
        margin-right: var(--x-nus-25);
    }

    &__consonance:last-child {
        text-align: right;
        margin-left: var(--x-nus-25);
    }

    .rangeSlider {
        position: relative;

        &__horizontal {
            height: 3px;
            width: 100%;
        }

        &__buffer {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            transform: translateY(-50%);
            height: 1px;
            background-color: rgba(var(--rgb-blue-navy), 0.3);
        }

        &__fill {
            position: absolute;
            background-color: rgb(var(--rgb-blue-hover));

            &__horizontal {
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        &__handle {
            position: absolute;
            top: calc((18px - 3px) * -0.5);
            width: 18px;
            height: 18px;
            background-color: rgb(var(--rgb-blue-hover));
            border: 2px solid rgb(var(--rgb-white));
            border-radius: 50%;
            cursor: pointer;
        }
    }

    // Erreur
    .c-form-error {
        position: relative;
        // Note : 9px corresponds à la moitié de la hauteur du handle
        margin-top: 8px + 9px;
    }
}
