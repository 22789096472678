@use "../base/typography";
@use "sass:math";

.s-cms-introduction {
    > :first-child {
        margin-top: 0 !important;
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    p {
        @extend .c-introduction;
        margin-top: var(--y-25);
        margin-bottom: var(--y-25);
    }

    a {
        @extend .c-underlined-link-dynamic;
        font-family: inherit;
        font-size: inherit;
        font-weight: 500;
        line-height: inherit;
        color: inherit;

        > svg {
            margin-left: math.div(2, 16) + em;
            margin-right: math.div(2, 16) + em;
        }
    }

    strong,
    b {
        // Note : La designer veut qu'on mettre le bold à semi-bold
        font-weight: 600;
    }

    strong a,
    b a {
        font-weight: inherit;
    }

    em,
    i {
        font-style: italic;
    }

    sup {
        position: relative;
        top: -0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }

    sub {
        position: relative;
        top: 0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }
}
