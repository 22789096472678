@use "../../base/typography";

.c-form {
    --form-grid-item-length: span 2;
}

.c-form {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px 65px;

        @media screen and (max-width: 1400px) { gap: 50px 60px; }
        @media screen and (max-width: 1200px) { gap: 50px 40px; }
        @media screen and (max-width: 1024px) { gap: 50px 30px; }
        @media screen and (max-width: 900px)  { gap: 50px 0; }
    }

    &__grid-item {
        grid-column: var(--form-grid-item-length);
        min-width: 0;

        &--half {
            @media screen and (min-width: 901px) { --form-grid-item-length: span 1; }
        }

        &--break + & {
            @media screen and (min-width: 901px) { grid-column-start: 1; }
        }

        &--less-top-spacing-30 {
            margin-top: -20px;
        }

        &--less-top-spacing-35-in-mobile {
            @media screen and (max-width: 900px) { margin-top: -15px; }
        }
    }

    &-note {
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: rgb(var(--rgb-grey-note));
        margin-top: 8px;
    }

    &-error {
        position: absolute;
        top: 100%;
        left: 0;
        font-family: var(--ff);
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: rgb(var(--rgb-red-error));
        margin-top: 8px;
    }

    &-error:empty {
        display: none;
    }

    .c-title-field {
        margin-bottom: -20px; // Mettre un peu moins de marge sous les titres dans les forms

        &__heading {
            @extend .c-medium-title;
        }
    }

    .c-subtitle-field {
        margin-bottom: -20px; // Mettre un peu moins de marge sous les titres dans les forms

        &__heading {
            @extend .c-small-title;
        }
    }
}
