.c-jobs-available {
    &__header {
        padding: var(--y-nus-25) var(--x-nus-30);
        background-color: rgb(var(--rgb-grey-background));
    }

    &__title {
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.4em;
    }

    &__content {
        background-color: rgb(246, 248, 249); // #F6F8F9
    }

    &__jobs-list {
        padding: 10px 0;
    }

    &__job {
        display: block;
        padding: 10px var(--x-nus-30);
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__job:hover {
        color: rgb(var(--rgb-blue-hover));
    }
}
