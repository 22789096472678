@use "../../abstract/placeholders";

.c-publication-card {
    --publication-card-image-ratio: 56.4103%; // 220/390
}

.c-publication-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &--large {
        @media screen and (min-width: 1201px) {
            flex-direction: row;
        }
    }

    // Picture
    &__picture-container {
        position: relative;
        margin-right: var(--y-nus-30);

        @media screen and (max-width: 800px) {
            margin-right: 0;
        }
    }

    &--large &__picture-container {
        @media screen and (min-width: 1201px) {
            width: 59.3632%; // 783/1319
            margin-right: 0;
            margin-bottom: var(--y-nus-30);
        }
    }

    &--in-aside-and-content &__picture-container {
        @media screen and (max-width: 1200px) and (min-width: 1001px) {
            margin-right: 0;
        }
    }

    &__picture {
        position: relative;
        padding-bottom: var(--publication-card-image-ratio);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(var(--rgb-blue-hover), 0.3);
            opacity: 0;
            transition: opacity 300ms;
            z-index: 1;
        }
    }

    &:hover &__picture::before {
        opacity: 1;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // Content
    &__content {
        flex: 1;
        margin-top: calc(0px - var(--y-nus-28));
        margin-left: var(--y-nus-30);
        z-index: 2;

        @media screen and (max-width: 800px) {
            margin-top: 0;
            margin-left: 0;
        }
    }

    &--large &__content {
        @media screen and (min-width: 1201px) {
            margin-top: auto;
            margin-left: calc(0px - 13.1918%); // 174/1319
        }
    }

    &--in-aside-and-content &__content {
        @media screen and (max-width: 1200px) and (min-width: 1001px) {
            margin-top: 0;
            margin-left: 0;
        }
    }

    &__content-inner {
        height: 100%;
        padding: var(--y-nus-50) var(--x-nus-45);
        background-color: rgb(var(--rgb-grey-background));
    }

    &--large &__content-inner {
        @media screen and (min-width: 1201px) {
            padding: var(--y-nus-65) var(--x-nus-100);
            margin-top: var(--y-nus-30);
        }
    }

    &__category {
        @extend %tag;
        margin-bottom: var(--y-nus-25);
    }

    &__title {
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
        margin-bottom: var(--y-nus-25);
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &--large &__title {
        @media screen and (min-width: 1201px) {
            font-size: var(--fz-26);
        }
    }

    &__date {
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
    }
}
