.c-job-card {
    display: flex;
    align-items: center;
    min-height: 138px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.3);

    @media screen and (max-width: 1024px) {
        min-height: 120px;
    }
    @media screen and (max-width: 768px)  {
        min-height: 100px;
    }

    @at-root .c-jobs__list &:first-child,
    #jobi-jobs-list > li:first-child & {
        border-top: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__surtitle {
        display: block;
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        margin-bottom: 5px;
    }

    &__title {
        font-size: var(--fz-22);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: rgb(var(--rgb-grey-background));
        border-radius: 50%;
        transition: background-color 300ms;
    }

    &:hover &__svg-container {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__svg {
        width: 13px;
        height: 13px;
        stroke: rgb(var(--rgb-blue-navy));
        transition: stroke 300ms;
    }

    &:hover &__svg {
        stroke: rgb(var(--rgb-white));
    }
}
