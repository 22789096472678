.c-fast-link-card {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--y-nus-23) var(--x-nus-30);
    background-color: rgb(var(--rgb-grey-background));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &--red {
        background-color: rgb(var(--rgb-red));
    }

    &__svg {
        width: 28px;
        height: 28px;
        stroke: rgb(var(--rgb-blue-navy));
        stroke-width: 6px;
        transition: stroke 300ms;
        margin-right: 23px;
        fill: none;

        @media screen and (max-width: 800px) {
            width: 25px;
            height: 25px;
            margin-right: 20px;
        }

        @media screen and (max-width: 600px) {
            width: 22px;
            height: 22px;
            margin-right: 18px;
        }
    }

    &:hover &__svg {
        stroke: rgb(var(--rgb-white));
    }

    &--red &__svg {
        stroke: rgb(var(--rgb-white));
    }

    &__title {
        flex: 1;
        font-size: var(--fz-18);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-white));
    }

    &--red &__title {
        color: rgb(var(--rgb-white));
    }
}
