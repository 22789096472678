.c-search-filter-and-sort-or-filter-selects {
    position: relative;
    z-index: 1;

    &__container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }
    }

    // Search filter field
    &__search-filter-field {
        flex: 1;
    }

    // Sort or filter selects
    &__sort-or-filter-selects {
        display: flex;
        align-items: center;
        max-width: calc(50% - var(--x-30));
        margin-left: var(--x-30);

        @media screen and (max-width: 1000px) {
            margin-top: var(--y-25);
            max-width: none;
            margin-left: 0;
        }

        @media screen and (max-width: 500px) {
            margin-top: 15px;
            flex-direction: column;
        }
    }

    &__sort-or-filter-select {
        height: 100%;
        width: 276px;
        min-width: 0;

        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }

    &__sort-or-filter-select:not(:last-child) {
        margin-right: var(--x-30);

        @media screen and (max-width: 1000px) {
            margin-right: var(--x-20);
        }

        @media screen and (max-width: 500px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    // Infos
    &__infos {
        margin-top: var(--y-20);
    }

    &__info-number-of-results {
        font-size: var(--fz-16);
        line-height: 1.3em;
    }
}
