@use "../../abstract/placeholders";
@use "../../base/typography";

.c-overlay-filter-by-dates {
    --overlay-filter-by-dates-width: 410px;
    --overlay-filter-by-dates-right-hide: calc(0px - var(--overlay-filter-by-dates-width));
    --overlay-filter-by-dates-right-show: 0px;
    --overlay-filter-by-dates-button-close-size: 50px;

    @media screen and (max-width: 600px) {
        --overlay-filter-by-dates-width: 100%;
        --overlay-filter-by-dates-right-hide: -100%;
        --overlay-filter-by-dates-button-close-size: 40px;
    }
}

.c-overlay-filter-by-dates {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 110;

    &__container {
        position: absolute;
        right: var(--overlay-filter-by-dates-right-hide);
        bottom: 0;
        width: var(--overlay-filter-by-dates-width);
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgb(var(--rgb-white));
        overflow: hidden;
        transition: right 800ms var(--in-out-quart);
    }

    // Button close
    &__button-close {
        position: absolute;
        top: var(--y-nus-30);
        right: var(--x-nus-30);
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--overlay-filter-by-dates-button-close-size);
        height: var(--overlay-filter-by-dates-button-close-size);
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        transition: background-color 300ms;
        z-index: 2;
    }

    &__button-close:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__button-close-x {
        display: block;
        @extend %x;
        --x-size: 18px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-white));
        --x-border-radius: 2px;

        @media screen and (max-width: 600px) {
            --x-size: 15px;
        }
    }

    // Content
    &__content {
        max-height: 100%;
        padding: calc(var(--overlay-filter-by-dates-button-close-size) + var(--y-nus-30)) var(--x-nus-50) var(--y-nus-30);

        // Note : Le overflow-y est à scroll, car sinon il un saut de 5px sur l'horizontal
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-white));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-grey-scrollbar-thumb));
        }
    }

    // Content transition
    &__content-transition {
        opacity: 0;
        transition: opacity 600ms 0ms;
    }

    // General
    &__title {
        display: block;
        font-size: var(--fz-21);
        font-weight: 500;
        line-height: 1.5em;
        margin-bottom: var(--y-nus-25);
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 500ms;
        z-index: -1;
    }

    &__button-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgb(var(--rgb-white));
        padding-top: var(--y-40);
        padding-bottom: var(--y-40);
        padding-left: var(--x-50);
        padding-right: var(--x-50);
        display: flex;
        justify-content: center;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-blue-navy;
        padding-right: 40px;
        padding-left: 40px;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-filter-by-dates {
    .c-overlay-filter-by-dates {
        visibility: visible;
        pointer-events: all;

        &__container {
            right: var(--overlay-filter-by-dates-right-show);
        }

        &__content-transition {
            opacity: 1;
            transition: opacity 600ms 300ms;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.25);
        }
    }

    .c-header__bottom {
        z-index: 105;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-filter-by-dates.closing-overlay-filter-by-dates {
    .c-overlay-filter-by-dates {
        &__container {
            right: var(--overlay-filter-by-dates-right-hide);
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0);
            transition-delay: 300ms;
        }
    }

    .c-header__bottom {
        z-index: 105;
    }
}
