@use "../base/typography";
@use "sass:math";

.s-cms-content {
    > :first-child {
        margin-top: 0 !important;
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    h2 + h3,
    h3 + h4 {
        margin-top: 0;
    }

    h2 {
        @extend .c-large-title;
        margin-top: var(--y-65);
        margin-bottom: var(--y-30);
    }

    h3 {
        @extend .c-medium-title;
        margin-top: var(--y-65);
        margin-bottom: var(--y-30);
    }

    h4 {
        @extend .c-small-title;
        margin-top: var(--y-65);
        margin-bottom: var(--y-30);
    }

    p {
        @extend .c-paragraph;
        margin-top: var(--y-25);
        margin-bottom: var(--y-25);
        --buttons-gap: 15px;

        &.buttons {
            display: flex;
            flex-wrap: wrap;
            align-items: start;
            width: calc(100% + var(--buttons-gap));
            margin-left: calc(var(--buttons-gap) / -2);
            margin-right: calc(var(--buttons-gap) / -2);

            > * {
                margin-right: calc(var(--buttons-gap) / 2);
                margin-left: calc(var(--buttons-gap) / 2);
                margin-top: 7px;
                margin-bottom: 7px;
            }

            &--first {
                margin-top: calc(var(--y-40) - 7px);
            }

            &--last {
                margin-bottom: calc(var(--y-40) - 7px);
            }
        }

        &:has(a.docs) {
            margin: 10px 0;
        }
    }

    pre {
        @extend .c-note;
        margin: var(--y-25) 0;
    }

    a:not(.primary-button):not(.secondary-button):not(.docs) {
        @extend .c-underlined-link-dynamic;

        > svg {
            margin-left: math.div(2, 16) + em;
            margin-right: math.div(2, 16) + em;
        }

        &.link-image,
        &.link-image:hover {
            box-shadow: none;
        }
    }

    // Link in others elements
    h2,
    h3,
    h4,
    p,
    pre,
    blockquote {
        a:not(.primary-button):not(.secondary-button):not(.docs) {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;
        }
    }

    li,p {
        a:not(.primary-button):not(.secondary-button):not(.docs) {
            font-weight: 500;
        }
        b,strong {
            a:not(.primary-button):not(.secondary-button):not(.docs) {
                font-weight: inherit;
            }
        }
    }

    a.primary-button {
        @extend .c-button;
        @extend .c-button--full-blue-navy;
        @extend .c-button--svg-fill;
    }

    a.secondary-button {
        @extend .c-button;
        @extend .c-button--empty-blue-navy-alpha-30;
        @extend .c-button--svg-fill;
    }

    // Même style que document sous contenu
    a.docs {
        display: flex;
        align-items: center;
        min-height: 64px;
        padding: var(--y-nus-25) var(--x-nus-35);
        text-decoration: none;
        background-color: rgb(var(--rgb-grey-background));
        cursor: pointer;

        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-blue-hover));
        }

        & + a.docs {
            margin-top: 10px;
        }

        > span:first-child {
            flex: 1;
            padding-right: 50px;

            @media screen and (max-width: 1024px) { padding-right: 40px; }
            @media screen and (max-width: 768px)  { padding-right: 30px; }
        }

        > span:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 39px;
            height: 39px;
            background-color: rgb(var(--rgb-white));
            border-radius: 50%;
            transition: background-color 300ms;
        }

        &:hover > span:last-child {
            background-color: rgb(var(--rgb-blue-hover));
        }

        > span:last-child svg {
            width: 21px;
            height: 21px;
            fill: rgb(var(--rgb-blue-navy));
            transition: fill 300ms;
        }

        &:hover > span:last-child svg {
            fill: rgb(var(--rgb-white));
        }
    }

    ol,
    ul {
        margin: var(--y-30) 0;
    }

    ol {
        @extend .c-list;
        @extend .c-list--ordered;
    }

    ul {
        @extend .c-list;
        @extend .c-list--unordered;
    }

    blockquote {
        @extend .c-quote;
        margin: var(--y-35) 0;
    }

    .table-wrapper {
        @extend .c-table;
        margin-top: var(--y-30);
        margin-bottom: var(--y-65);
    }

    .emphasis {
        @extend .c-emphasis;
        margin-top: var(--y-30);
        margin-bottom: var(--y-65);
    }

    .table-wrapper,
    .emphasis {
        & + pre { // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
            margin-top: calc(0px - var(--y-45));
        }

        & + .table-wrapper,
        & + .emphasis { // Modifier la marge pour les tableaux sous les tableaux
            margin-top: calc(0px - var(--y-35));
        }
    }

    .emphasis-stat {
        @extend .c-emphasis-stat;
        margin-top: var(--y-30);
        margin-bottom: var(--y-65);

        & + .emphasis-stat {
            margin-top: calc(0px - var(--y-65));
        }
    }

    strong,
    b {
        // Note : La designer veut qu'on mettre le bold à semi-bold
        font-weight: 600;
    }

    em,
    i {
        font-style: italic;
    }

    sup {
        position: relative;
        top: -0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }

    sub {
        position: relative;
        top: 0.3em;
        font-size: max(0.7em, 1.2rem);
        vertical-align: baseline;
    }

    .fr-video {

        //Rompre le texte
        &.fr-dvb {
            .videoWrapper {
                display: block;
                float: none;
                margin: 30px auto;
            }

            //Aligner à gauche
            &.fr-fvl {
                .videoWrapper { margin-left: 0; }
            }

            //Aligner à droite
            &.fr-fvr {
                .videoWrapper { margin-right: 0; }
            }
        }

        //En ligne
        &.fr-dvi {
            .videoWrapper {
                display: inline-block;
                float: none;
                vertical-align: bottom;
                width: 100%;
            }

            //Aligner à gauche
            &.fr-fvl {
                .videoWrapper {
                    float: left;
                    margin: 10px 20px 10px 0;
                }
            }

            //Aligner à droite
            &.fr-fvr {
                .videoWrapper {
                    float: right;
                    margin: 10px 0 10px 20px;
                }
            }
        }

        .videoWrapper {
            max-width: 560px;

            .ytVideo {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }
    }

    video {
        max-width: 560px;
        width: 100%;
    }

    img { // Image no caption
        position: relative;
        max-width: 100%;

        &.fr-dib {
            display: block;
            float: none;
            margin: 30px auto;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {
            display: inline-block;
            float: none;
            vertical-align: bottom;

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
            }
        }
    }

    .fr-img-caption { // Image with caption
        img {
            width: 100%;
        }

        //Rompre le texte
        &.fr-dib {
            display: block;
            float: none;
            margin: 30px auto;
            text-align: center;

            //Aligner à gauche
            &.fr-fil {
                margin-left: 0;
                text-align: left;
            }

            //Aligner à droite
            &.fr-fir {
                margin-right: 0;
                text-align: right;
            }

            .fr-img-wrap .fr-inner {
                display: block;
                @extend .c-note;
            }
        }

        //En ligne
        &.fr-dii {
            display: inline-block;
            float: none;
            text-align: center;
            vertical-align: bottom;

            //Aligner à gauche
            &.fr-fil {
                float: left;
                margin-left: 0;
                text-align: left;
            }

            //Aligner à droite
            &.fr-fir {
                float: right;
                margin-right: 0;
                text-align: right;
            }
        }
    }

    span.fr-fic.oc-img-rounded img,
    img.fr-fic.oc-img-rounded {
        border-radius: 100%;
        background-clip: padding-box;
    }

    span.fr-fic.oc-img-bordered img,
    img.fr-fic.oc-img-bordered {
        border: solid 10px #ccc;
    }

    // Rubric
    &--rubric {
        h3 {
            margin-top: 50px;
            margin-bottom: 25px;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 1.4em;

            @media screen and (max-width: 375px) { font-size: 2.1rem; }
            @media screen and (max-width: 320px) { font-size: 2rem; }
        }

        h4 {
            margin-top: 50px;
            margin-bottom: 25px;
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 1.5em;
        }
    }

    // Organization
    &--organization {
        --paragraph-font-size: var(--fz-15);
        --paragraph-line-height: 1.6em;

        p {
            margin-top: var(--y-20);
            margin-bottom: var(--y-20);
        }

        ol,
        ul {
            margin: 15px 0;
        }

        ol > li,
        ul > li {
            --ordered-list-padding: 25px;
            padding-left: 0;
        }

        ol li:not(:first-child),
        .ordered-list li:not(:first-child),
        ul li:not(:first-child),
        .unordered-list li:not(:first-child) {
            margin-top: 2px;
        }

        ol li ol,
        .ordered-list li ol,
        ul li ol,
        .unordered-list li ol,
        ol li ul,
        .ordered-list li ul,
        ul li ul,
        .unordered-list li ul {
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }
}
