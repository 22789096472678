@use "../../abstract/placeholders";
@use "../../base/typography";

.c-overlay-calendar {
    --overlay-calendar-width: 580px;
    --overlay-calendar-right-hide: calc(0px - var(--overlay-calendar-width));
    --overlay-calendar-right-show: 0px;
    --overlay-calendar-button-close-size: 50px;
    --overlay-calendar-legend-height: 90px;

    @media screen and (max-width: 600px) {
        --overlay-calendar-width: 100%;
        --overlay-calendar-right-hide: -100%;
        --overlay-calendar-button-close-size: 40px;
        --overlay-calendar-legend-height: 100px;
    }

    @media screen and (max-width: 450px) {
        --overlay-calendar-legend-height: 130px;
    }
}

.c-overlay-calendar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 110;

    &__container {
        position: absolute;
        right: var(--overlay-calendar-right-hide);
        bottom: 0;
        width: var(--overlay-calendar-width);
        height: calc(var(--vh, 1vh) * 100);
        background-color: rgb(var(--rgb-white));
        overflow: hidden;
        transition: right 800ms var(--in-out-quart);
    }

    // Button close
    &__button-close {
        position: absolute;
        top: var(--y-nus-30);
        right: var(--x-nus-30);
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--overlay-calendar-button-close-size);
        height: var(--overlay-calendar-button-close-size);
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        transition: background-color 300ms;
        z-index: 2;
    }

    &__button-close:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__button-close-x {
        display: block;
        @extend %x;
        --x-size: 18px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-white));
        --x-border-radius: 2px;

        @media screen and (max-width: 600px) {
            --x-size: 15px;
        }
    }

    // Content
    &__content {
        display: flex;
        height: calc((var(--vh, 1vh) * 100) - var(--overlay-calendar-legend-height));
        max-height: calc((var(--vh, 1vh) * 100) - var(--overlay-calendar-legend-height));
        padding: calc(var(--overlay-calendar-button-close-size) + var(--y-nus-30) + var(--y-nus-70)) var(--x-nus-80) var(--y-nus-70);

        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-white));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-grey-scrollbar-thumb));
        }

        @media screen and (max-height: 800px) {
            padding-top: calc(var(--overlay-calendar-button-close-size) + var(--y-nus-30) + var(--y-nus-50));
            padding-bottom: var(--y-nus-60);
        }

        @media screen and (max-height: 700px) {
            padding-top: calc(var(--overlay-calendar-button-close-size) + var(--y-nus-30) + var(--y-nus-30));
            padding-bottom: var(--y-nus-50);
        }
    }

    // Content transition
    &__content-transition {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        opacity: 0;
        transition: opacity 600ms 0ms;
        margin: auto 0;
    }

    // Calendar
    &__calendar {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    // Button all dates
    &__button-all-dates {
        @extend .c-button;
        @extend .c-button--empty-blue-navy-alpha-30;
        margin-top: var(--y-nus-45);
    }

    // Legend
    &__legend {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--overlay-calendar-legend-height);
        padding: 0 var(--x-nus-30);
        background-color: rgb(var(--rgb-grey-background));
        opacity: 0;
        transition: opacity 600ms 0ms;
        z-index: 3;

        @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: normal;
            justify-content: center;
        }
    }

    &__legend-left {
        @media screen and (max-width: 600px) {
            margin-bottom: 10px;
        }
    }

    &__legend-title {
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.6em;
    }

    &__legend-right {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__legend-event {
        display: block;
        font-size: var(--fz-14);
        line-height: 1.6em;
        color: rgb(var(--rgb-blue-navy));
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-blue-navy), 0.5);
        margin-right: var(--x-nus-30);

        @media screen and (max-width: 450px) {
            margin-right: 100%;
            margin-bottom: 10px;
        }
    }

    &__legend-today {
        margin-right: var(--x-nus-30);
    }

    &__legend-today,
    &__legend-selected {
        display: flex;
        align-items: center;
    }

    &__legend-today-number,
    &__legend-selected-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6em;
        margin-right: 8px;
    }

    &__legend-today-number {
        border: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    }

    &__legend-selected-number {
        background-color: rgb(var(--rgb-blue-hover));
        color: rgb(var(--rgb-white));
    }

    &__legend-today-text,
    &__legend-selected-text {
        font-size: var(--fz-14);
        line-height: 1.6em;
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 500ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-calendar {
    .c-overlay-calendar {
        visibility: visible;
        pointer-events: all;

        &__container {
            right: var(--overlay-calendar-right-show);
        }

        &__content-transition,
        &__legend {
            opacity: 1;
            transition: opacity 600ms 300ms;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.25);
        }
    }

    .c-header__bottom {
        z-index: 105;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-calendar.closing-overlay-calendar {
    .c-overlay-calendar {
        &__container {
            right: var(--overlay-calendar-right-hide);
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0);
            transition-delay: 300ms;
        }
    }

    .c-header__bottom {
        z-index: 105;
    }
}
