/*
|--------------------------------------------------------------------------
| Les Placeholders
|--------------------------------------------------------------------------
|
| Les placeholders permettent de réutiliser certaines propriétés CSS.
|
*/

%x {
    display: inline-block;
    width: var(--x-size, 10px);
    height: var(--x-size, 10px);
    position: var(--x-position, relative);
    top: var(--x-top, 0);
    left: var(--x-left, 0);
    transform: translate(var(--x-translate, 0));

    &::before,
    &::after {
        content: "";
        width: 100%;
        height: var(--x-thickness, 1px);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: var(--x-color, rgb(var(--rgb-blue-navy)));
        border-radius: var(--x-border-radius, 0px);
        transition: background-color 300ms;
    }

    &::before { transform: translate(-50%, -50%) rotate(45deg); }
    &::after { transform: translate(-50%, -50%) rotate(-45deg); }
}

%tag {
    display: block;
    width: fit-content;
    padding: 6px 16px;
    background-color: var(--tag-background-color, rgba(var(--rgb-blue-navy), 0.1));
    font-size: var(--fz-13);
    font-weight: var(--tag-font-weight, 600);
    line-height: 1.3em;
    color: var(--tag-color, rgb(var(--rgb-blue-navy)));
    transition: background-color 300ms, color 300ms;
}

// Fix border radius safari
%border-radius {
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
