.c-input-field {
    --input-field-color: rgb(var(--rgb-blue-navy));
    --input-field-placeholder-color: rgb(var(--rgb-grey-note));
    --input-field-background-color: rgb(var(--rgb-grey-background));
    --input-field-border-color: rgba(var(--rgb-blue-navy), 0.3);
    --input-field-border-color-active: rgb(var(--rgb-blue-navy));
    --input-field-border-color-error: rgb(var(--rgb-red-error));

    &--survey {
        --input-field-background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
    }
}

.c-input-field {
    position: relative;

    &--survey {
        padding: var(--y-nus-25) var(--x-nus-35);
        background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
    }

    &__input-container {
        position: relative;
    }

    &__input {
        width: 100%;
        padding: 8px 0;
        font-family: var(--ff);
        font-size: var(--fz-16);
        line-height: 1.5em;
        color: var(--input-field-color);
        border: 0;
        border-bottom: 1px solid var(--input-field-border-color);
        border-radius: 0; // Nécessaire même si 0 pour éviter les coins ronds en iOS
        background-color: var(--input-field-background-color);
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;

        // Cacher l'icone de calendrier pour les champs "date" et l'icone d'horloge pour les champs "time"
        &[type="time"],
        &[type="date"] {
            &::-webkit-calendar-picker-indicator {
                display: none;
            }

            &:in-range::-webkit-datetime-edit-year-field,
            &:in-range::-webkit-datetime-edit-month-field,
            &:in-range::-webkit-datetime-edit-day-field,
            &:in-range::-webkit-datetime-edit-text {
                color: transparent;
            }
        }
    }

    &__input:focus {
        border-color: var(--input-field-border-color-active);
    }

    &__input {
        box-shadow: 0 0 0 100px var(--input-field-background-color) inset; // Choisir la couleur voulu pour le input (Cache le background bleu de l'autofill)
    }

    &--error &__input {
        border-color: var(--input-field-border-color-error);
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--input-field-placeholder-color); }
    ::-moz-placeholder { color: var(--input-field-placeholder-color); }
    :-ms-input-placeholder {  color: var(--input-field-placeholder-color); }
    :-moz-placeholder { color: var(--input-field-placeholder-color); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-size: var(--fz-16) !important;
        border-bottom: 1px solid var(--input-field-border-color-active) !important;
        -webkit-text-fill-color: var(--input-field-color);
        caret-color: var(--input-field-color);
    }

    :-webkit-autofill::first-line {
        font-family: var(--ff);
        font-size: var(--fz-16);
    }

    // Erreur
    &--survey .c-form-error {
        position: relative;
    }
}
