@use "../../base/typography";

.c-footer {
    position: relative;

    &__container {
        padding: var(--y-nus-50) var(--x-60) var(--y-nus-35);
        background-color: rgb(var(--rgb-blue-dark-navy));
        overflow: hidden;
    }

    // Button back to top
    &__button-back-to-top {
        position: absolute;
        top: 0;
        right: var(--x-60);
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: rgb(var(--rgb-red));
        border-radius: 50%;
        transition: background-color 300ms;
        z-index: 1;
    }

    &__button-back-to-top:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__button-back-to-top-svg {
        width: 16px;
        height: 16px;
        stroke: rgb(var(--rgb-white));
    }

    // Top
    &__top {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            justify-content: normal;
        }
    }

    // Top left
    &__top-left {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 325px;
        margin-right: var(--x-100);

        @media screen and (max-width: 1400px) {
            max-width: 300px;
        }

        @media screen and (max-width: 1300px) {
            max-width: 270px;
        }

        @media screen and (max-width: 1200px) {
            flex-direction: row;
            max-width: none;
            margin-right: 0;
        }

        @media screen and (max-width: 800px) {
            flex-direction: column;
        }
    }

    // Top left - logo
    &__logo-element {
        margin-top: var(--y-nus-60);
        margin-bottom: var(--y-nus-45);

        @media screen and (max-width: 1200px) {
            margin-top: 0;
            margin-right: var(--x-50);
        }
    }

    &__logo-link {
        display: block;
        width: 100%;
        max-width: 255px;

        @media screen and (max-width: 1200px) {
            max-width: 241px;
        }

        @media screen and (max-width: 800px) {
            max-width: 175px;
        }
    }

    &__logo-img {
        max-width: 100%;
        vertical-align: bottom;
    }

    // Top left - social medias and newsletter
    &__social-medias-element-and-newsletter-element {
        margin-top: auto;
        @media screen and (max-width: 1200px) {
            flex: 1;
        }
    }

    &__social-medias-title,
    &__newsletter-title {
        display: block;
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        margin-bottom: 12px;
    }

    // Top left - social medias
    &__social-medias-element {
        margin-bottom: var(--y-nus-30);
    }

    &__social-medias-list {
        display: flex;
    }

    &__social-medias-list-item:not(:last-child) {
        margin-right: 17px;
    }

    &__social-media {
        display: block;
        width: 20px;
        height: 20px;
    }

    &__social-media-svg {
        width: 100%;
        height: 100%;
        fill: rgba(var(--rgb-white), 0.6);
        transition: fill 300ms;
    }

    &__social-media:hover &__social-media-svg {
        fill: rgb(var(--rgb-white));
    }

    // Top left - newsletter
    &__newsletter-field {
        .c-newsletter-field,
        .c-newsletter-field__input-container {
            position: relative;
        }

        .c-newsletter-field__input {
            font-family: var(--ff);
            font-size: var(--fz-14);
            font-weight: 500;
            line-height: 1.3em;
            color: rgb(var(--rgb-blue-navy));
            width: 100%;
            padding: 16px 52px 16px 20px;
            background-color: rgb(var(--rgb-white));
            border: 0;
            box-shadow: 0 0 0 100px rgb(var(--rgb-white)) inset;
            overflow: hidden;
            transition: color 300ms;

            &::-webkit-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &::-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &:-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &::placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
        }

        .c-newsletter-field__button-submit {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;

            // Note : ce before sert à augmenter la zone de touch du bouton
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
            }
        }

        .c-newsletter-field__button-submit-svg {
            width: 16px;
            height: 16px;
            stroke: rgb(var(--rgb-blue-navy));
            transform: rotate(90deg);
            transition: stroke 300ms;
        }

        .c-newsletter-field__button-submit:hover .c-newsletter-field__button-submit-svg {
            stroke: rgb(var(--rgb-blue-hover));
        }

        .c-newsletter-field__error {
            @media screen and (max-width: 800px) {
                margin-top: 0;
            }
        }
    }

    &__button-contact-element {
        margin-top: 20px;

        @media screen and (min-width: 801px) {
            display: none;
        }
    }

    &__button-contact {
        @extend .c-button;
        @extend .c-button--full-blue-inoffensive;
        justify-content: center;
        width: 100%;
    }

    // Top right
    &__top-right {
        flex: 1;

        @media screen and (max-width: 1200px) {
            margin-top: var(--y-nus-50);
        }

        @media screen and (max-width: 800px) {
            display: none;
        }
    }

    // Top right - blocks infos
    &__blocks-infos-title {
        display: block;
        padding-top: 15px;
        border-top: 1px solid rgba(var(--rgb-white), 0.2);
        font-size: var(--fz-18);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        margin-bottom: 15px;
    }

    &__blocks-infos-list {
        display: flex;
    }

    &__blocks-infos-list-item {
        flex: 1;
    }

    &__blocks-infos-list-item:not(:last-child) {
        margin-right: var(--x-30);
    }

    &__block-infos {
        height: 100%;
        padding: var(--y-nus-50) var(--x-nus-40);
        background-color: rgb(var(--rgb-blue-navy));

        strong {
            display: block;
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.5em;
            color: rgb(var(--rgb-white));
        }

        p {
            display: block;
            font-size: var(--fz-15);
            line-height: 1.5em;
            color: rgba(var(--rgb-white), 0.6);
        }

        sub {
            vertical-align: super;
            font-size: 0.7em;
        }

        sup {
            vertical-align: sub;
            font-size: 0.7em;
        }

        strong + p,
        p + p {
            margin-top: var(--y-nus-20);
        }

        a {
            @extend .c-underlined-link-white;
        }

        strong + a,
        p + a {
            margin-top: var(--y-nus-20);
        }

        a + a {
            margin-top: 12px;
        }

        a + strong {
            margin-top: var(--y-nus-20);
        }
    }

    // Bottom
    &__bottom {
        display: flex;
        justify-content: space-between;
        padding-top: var(--y-nus-35);
        border-top: 1px solid rgba(var(--rgb-white), 0.2);
        margin-top: var(--y-nus-50);

        @media screen and (max-width: 600px) {
            align-items: flex-end;
        }

        @media screen and (max-width: 400px) {
            flex-direction: column;
            align-items: normal;
        }
    }

    // Bottom left
    &__bottom-left {
        @media screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }

        @media screen and (max-width: 400px) {
            margin-bottom: 15px;
        }
    }

    // Bottom left - copyright
    &__copyright {
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        margin-right: var(--x-30);

        @media screen and (max-width: 600px) {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }

    // Bottom left - judicial advice link
    &__judicial-advice-link {
        @extend .c-underlined-link-white;
        width: fit-content;
    }

    // Bottom right - blanko
    &__blanko {
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        transition: opacity 300ms;
    }

    &__blanko:hover {
        opacity: 0.6;
    }
}
