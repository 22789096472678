@use "../../abstract/placeholders";
@use "../../base/typography";

.c-overlay-search {
   --c-overlay-search-search-field-container-height: calc(var(--y-nus-40) + (var(--fz-13) * 1.3) + var(--y-nus-25) + 20px + (var(--fz-20) * 1.3) + 20px);
}

.c-overlay-search {
    position: fixed;
    top: var(--alerts-height);
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    transition: top 500ms var(--in-out-cubic);
    z-index: 110;

    &__container {
        position: relative;
        top: var(--header-top-height);
        left: var(--x-60);
        width: calc(100% - var(--x-60) - var(--x-60));
        padding-top: var(--header-bottom-height);
        background-color: rgb(var(--rgb-white));
        overflow: hidden;
        transition: box-shadow 300ms;

        @media screen and (max-width: 600px) {
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 0;

            // Note : Le before est utilisé pour voir la scrollbar de 5px à droite lorsqu'il y a beaucoup de contenu
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 5px;
                left: 0;
                height: var(--header-height);
                background-color: rgb(var(--rgb-white));
                z-index: 2;
            }
        }
    }

    // Sections
    &__sections {
        position: relative;
        height: 0px;
        transition: height 800ms var(--in-out-quart);
    }

    // Search field title
    // Suggestions links title
    &__search-field-title,
    &__suggestions-links-title {
        display: block;
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: var(--y-nus-25);
    }

    // Search field
    &__search-field-container {
        position: relative;
        padding: var(--y-nus-40) var(--x-190) 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 600ms 0ms;
        z-index: 2;

        @media screen and (max-width: 600px) {
            top: var(--header-height);
            padding-right: var(--x-60);
            padding-left: var(--x-60);
        }

        // Note : Le after est utilisé pour voir la scrollbar de 5px à droite lorsqu'il y a beaucoup de contenu
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 5px;
            left: 0;
            bottom: 0;
            background-color: rgb(var(--rgb-white));
            z-index: -1;
        }
    }

    &__search-field {
        .c-search-field__input-container {
            position: relative;
        }

        .c-search-field__input {
            font-family: var(--ff);
            font-size: var(--fz-20);
            line-height: 1.3em;
            color: rgb(var(--rgb-blue-navy));
            width: 100%;
            padding: 20px 114px 20px 30px;
            background-color: rgb(var(--rgb-grey-background));
            border: 0;
            box-shadow: 0 0 0 100px rgb(var(--rgb-grey-background)) inset;
            overflow: hidden;
            transition: color 300ms;

            @media screen and (max-width: 600px) {
                padding: 20px 104px 20px 20px;
            }

            &::-webkit-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &::-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &:-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
            &::placeholder { color: rgba(var(--rgb-blue-navy), 0.6); }
        }

        .c-search-field__button-delete {
            position: absolute;
            right: 66px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;

            @media screen and (max-width: 600px) {
                right: 56px;
            }

            // Note : ce before sert à augmenter la zone de touch du bouton
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
            }
        }

        .c-search-field__input:not(:placeholder-shown) ~ .c-search-field__button-delete {
            opacity: 1;
            pointer-events: all;
        }

        .c-search-field__button-delete-x {
            @extend %x;
            --x-size: 18px;
            --x-thickness: 2px;
            --x-color: rgb(var(--rgb-blue-navy));
            --x-border-radius: 2px;
        }

        .c-search-field__button-delete:hover .c-search-field__button-delete-x {
            --x-color: rgb(var(--rgb-blue-hover));
        }

        .c-search-field__button-submit {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;

            @media screen and (max-width: 600px) {
                right: 20px;
            }

            // Note : ce before sert à augmenter la zone de touch du bouton
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
            }
        }

        .c-search-field__button-submit-svg {
            width: 18px;
            height: 18px;
            stroke: rgb(var(--rgb-blue-navy));
            transition: stroke 300ms;
        }

        .c-search-field__button-submit:hover .c-search-field__button-submit-svg {
            stroke: rgb(var(--rgb-blue-hover));
        }
    }

    // Section
    &__section {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding-top: var(--c-overlay-search-search-field-container-height);
        padding-right: var(--x-190);
        padding-left: var(--x-190);
        max-height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--header-top-height));
        opacity: 0;
        pointer-events: none;
        transition: opacity 600ms 0ms;

        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-white));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-grey-scrollbar-thumb));
        }

        @media screen and (max-width: 600px) {
            padding-top: calc(var(--header-height) + var(--c-overlay-search-search-field-container-height));
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            height: calc(var(--vh, 1vh) * 100);
            max-height: calc(var(--vh, 1vh) * 100);
        }
    }

    // Suggestions links
    &__suggestions-links {
        padding-top: var(--y-nus-45);
        padding-bottom: var(--y-nus-55);
    }

    &__suggestions-links-list {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
    }

    &__suggestion-link {
        @extend %tag;
        --tag-background-color: rgb(var(--rgb-blue-navy));
        --tag-font-weight: 500;
        --tag-color: rgb(var(--rgb-white));
    }

    &__suggestion-link:hover {
        --tag-background-color: rgb(var(--rgb-blue-hover));
    }

    // Fast results
    &__fast-results {
        padding-top: var(--y-nus-20);
        padding-bottom: var(--y-nus-55);
    }

    &__fast-results-number {
        display: block;
        font-size: var(--fz-16);
        line-height: 1.3em;
        margin-bottom: var(--y-nus-50);
    }

    &__button-all-results {
        @extend .c-button;
        @extend .c-button--full-blue-navy;
        margin-top: var(--y-nus-50);
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin sectionSearch($section) {
    .show-overlay-search:not(.closing-overlay-search)[data-section="#{$section}"],
    .show-overlay-search.closing-overlay-search[data-section-closing="#{$section}"] {
        .c-overlay-search [data-section="#{$section}"] {
            pointer-events: all;
            opacity: 1;
            transition: opacity 600ms 300ms;
            z-index: 1;
        }

        .c-overlay-search__search-field-container {
            pointer-events: all;
            opacity: 1;
            transition: opacity 600ms 300ms;
        }
    }
}

@include sectionSearch(suggestions);
@include sectionSearch(fast-results);


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-search {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-search {
        visibility: visible;
        pointer-events: all;

        &__container {
            box-shadow: 0 57px 70px rgba(4, 22, 43, 0.4);
        }
    }

    .c-header__button-search {
        background-color: rgb(var(--rgb-blue-hover));
        &-open { opacity: 0; }
        &-close { opacity: 1; }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-search.closing-overlay-search {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-search {
        &__container {
            box-shadow: 0 57px 70px rgba(4, 22, 43, 0);
            transition: box-shadow 300ms 500ms;
        }
    }
}
