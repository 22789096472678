@use "../../abstract/placeholders";
@use "../../base/typography";

.c-search-filter-and-filter-swiper {
    position: relative;
    z-index: 1;

    &__container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }
    }

    // Search filter field
    &__search-filter-field {
        flex: 1;
    }

    // Filter swiper
    &__filter-swiper {
        flex: 1;
        min-width: 0;
        max-width: fit-content;
        margin-left: var(--x-30);

        @media screen and (max-width: 1000px) {
            max-width: none;
            margin-top: var(--y-20);
            margin-left: 0;
        }
    }

    &__filter-swiper--desktop {
        @media screen and (max-width: 500px) {
            display: none;
        }
    }

    &__filter-swiper--mobile {
        @media screen and (min-width: 501px) {
            display: none;
        }
    }

    // Infos
    &__infos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px var(--x-50);
        margin-top: var(--y-20);
    }

    &__info-number-of-results,
    &__info-suggestions {
        font-size: var(--fz-16);
        line-height: 1.3em;
    }

    &__info-suggestions-link {
        @extend .c-underlined-link-blue-navy;
    }
}
