@use "../../abstract/placeholders";
@use "../../base/typography";

.c-page-title {
    --page-title-image-ratio: 56.25%; // 495/880
}

.c-page-title {
    &__breadcrumbs-and-button-share {
        margin-bottom: var(--y-30);
    }

    &--in-section-aside-and-content &__breadcrumbs-and-button-share {
        margin-right: calc(0px - var(--x-60));

        @media screen and (max-width: 1000px) {
            margin-right: 0;
        }
    }

    &__title {
        font-size: var(--fz-55);
        font-weight: 600;
        line-height: 1.3em;

        &.u-font-size-50 { font-size: var(--fz-50); }
        &.u-font-size-45 { font-size: var(--fz-45); }
        &.u-font-size-40 { font-size: var(--fz-40); }
    }

    // Aside mobile
    &__aside-mobile {
        margin-top: var(--y-30);

        @media screen and (min-width: 1001px) {
            display: none;
        }
    }

    &__aside-mobile-element:not(:last-child) {
        margin-bottom: var(--y-30);
    }

    // Infos
    &__infos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px var(--x-25);
        margin-top: var(--y-30);
    }

    &__info-categories {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px 10px;
    }

    &__info-category {
        @extend %tag;
    }

    &__info-date {
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.5em;
    }

    &__link-more-information {
        @extend .c-underlined-link-blue-navy;
    }

    // Picture credits
    &__picture-credits {
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: block;
        max-width: calc(100% - 20px);
        padding: 4px 10px;
        background-color: rgba(2, 17, 34, 0.7); // #021122
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        z-index: 1;
    }

    // Picture
    &__picture-container {
        position: relative;
        margin-top: var(--y-30);
    }

    &__picture {
        position: relative;
        padding-bottom: var(--page-title-image-ratio);
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
