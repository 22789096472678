/*
|--------------------------------------------------------------------------
| Emplacement temporaire des styles des développeurs Back-end
|--------------------------------------------------------------------------
|
| Tous les ajouts que les programmeurs Back-end effectueront se
| retrouveront ici, afin de leur économiser du temps et garder le SCSS
| organisé. Idéalement, un développeur Front-end viendra répartir ce code
| dans les bons fichiers. Optimalement, ce fichier doit être vide.
|
*/
@use "./typography";

.dynamicForm {
    .c-input-field__input, .c-textarea-field__textarea {
        box-shadow: 0 0 0 100px white inset;
    }

    .c-select-field {
        .tail-select, .tail-select.active {
            .select-label {
                background: rgb(237, 240, 243); // Gris arrière plan
            }
        }
        .dropdown-option {
            background: rgb(237, 240, 243); // Gris arrière plan
        }
    }
}

#contact-form {
    .dynamicForm {
        .c-input-field__input, .c-textarea-field__textarea {
            box-shadow: 0 0 0 100px rgb(var(--rgb-grey-background)) inset;
        }

        .c-select-field  {
            .tail-select .select-label, .dropdown-option {
                background: white;
            }

            .dropdown-option:hover, .dropdown-option.selected {
                background-color: var(--select-field-option-background-hover);
            }
        }
    }
}

.consent-link {
    @extend .c-underlined-link-dynamic;
}

#infolettrePage p {
    font-size: 15px;
    color: white;
}

.o-layout--page-newsletter {
    #infolettrePage p {
        font-size: 20px;
        color: rgb(var(--rgb-blue-navy));
        margin-bottom: var(--y-25);
        line-height: 35px;
    }
}


.form-builder-submit.disabled {
    opacity: 0.6;
    pointer-events: none;
}


.dynamicForm {
    .c-form__grid-item--clear.c-form__grid-item--half {
        grid-column-start: 1;
    }
}

.c-checkbox-field__text p {
    display: inline;
}
