.c-date-to-remember-card {
    display: block;
    height: 100%;
    padding: var(--y-nus-25) var(--x-nus-35);
    background-color: rgb(var(--rgb-grey-background));
    transition: background-color 300ms;

    &--blue {
        background-color: rgb(var(--rgb-blue-inoffensive));
    }

    &--blue[href]:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &:not(:last-child) {
        margin-bottom: var(--y-nus-20);
    }

    &__surtitle {
        display: block;
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
        margin-bottom: 5px;
    }

    &[href]:hover &__surtitle {
        color: rgb(var(--rgb-blue-hover));
    }

    &--blue &__surtitle {
        color: rgb(var(--rgb-white));
    }

    &--blue[href]:hover &__surtitle {
        color: rgb(var(--rgb-white));
    }

    &__title {
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &[href]:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &--blue &__title {
        color: rgb(var(--rgb-white));
    }

    &--blue[href]:hover &__title {
        color: rgb(var(--rgb-white));
    }
}
