@use "../../base/typography";

.c-filter-swiper-and-buttons {
    position: relative;
    z-index: 1;

    &__container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }
    }

    // Filter swiper
    &__filter-swiper {
        flex: 1;
        min-width: 0;
    }

    &__filter-swiper--desktop {
        @media screen and (max-width: 500px) {
            display: none;
        }
    }

    &__filter-swiper--mobile {
        @media screen and (min-width: 501px) {
            display: none;
        }
    }

    // Buttons
    &__buttons {
        display: flex;
        align-items: center;
        margin-left: var(--x-30);

        @media screen and (max-width: 1000px) {
            margin-top: var(--y-25);
            margin-left: 0;
        }

        @media screen and (max-width: 500px) {
            margin-top: 10px;
            flex-direction: column;
        }
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-blue-navy;
        @extend .c-button--svg-stroke;
        height: 100%;
        padding-right: 40px;
        padding-left: 40px;

        @media screen and (max-width: 1000px) {
            padding-right: 35px;
            padding-left: 35px;
        }

        @media screen and (max-width: 500px) {
            justify-content: space-between;
            width: 100%;
            padding-left: var(--x-nus-40);
            padding-right: var(--x-nus-40);
        }
    }

    &__button:not(:last-child) {
        margin-right: var(--x-30);

        @media screen and (max-width: 1000px) {
            margin-right: var(--x-20);
        }

        @media screen and (max-width: 500px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    // Infos
    &__infos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px var(--x-20);
        margin-top: var(--y-20);
    }

    &__info-number-of-results,
    &__info-active-filter {
        font-size: var(--fz-16);
        line-height: 1.3em;
    }

    &__info-number-of-results {
        margin-right: calc(var(--x-50) - var(--x-20));
    }

    &__info-active-filter {
        font-weight: 500;
    }

    &__link-reset {
        @extend .c-underlined-link-blue-navy;
    }
}
