.c-page-card {
    --page-card-image-ratio: 56.25%; // 495/880
}

.c-page-card {
    display: block;
    width: 100%;

    // Picture
    &__picture-container {
        position: relative;
    }

    &__picture {
        position: relative;
        padding-bottom: var(--page-card-image-ratio);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(var(--rgb-blue-hover), 0.3);
            opacity: 0;
            transition: opacity 300ms;
            z-index: 1;
        }
    }

    &:hover &__picture::before {
        opacity: 1;
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // Content
    &__content {
        margin-top: var(--y-25);
    }

    &__title {
        font-size: var(--fz-21);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }
}
