.c-section-header {
    padding-top: var(--y-30);
    border-top: 1px solid rgb(var(--rgb-blue-navy));
    margin-bottom: var(--y-50);

    &__title {
        font-size: var(--fz-45);
        font-weight: 600;
        line-height: 1.3em;
    }
}
