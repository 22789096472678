@use "../../base/typography";

// =================================================
// --------------------- Popup ---------------------
// =================================================

:root {
    --byscuit-text-color: var(--rgb-blue-navy);                 // Couleur texte interfaces
    --byscuit-text-hover-color: var(--rgb-blue-hover);          // Couleur hover texte interfaces
    --byscuit-toggle-off-bg-color: var(--rgb-grey-background);  // Couleur toggle cat off
    --byscuit-toggle-on-bg-color: 19, 193, 100;                 // Couleur toggle cat on
}

.cc_div * {
    font-style: revert !important;
    font-family: var(--ff) !important;
}

// Boite complète
#cm {
    max-width: 40em !important;
    border-radius: unset !important;
    padding: 1.7em !important;

    // Container titre + texte + boutons
    #c-inr {
        display: flex;
        flex-direction: column;

        // Sous-container titre + texte
        #c-inr-i {
            width: 100%;
            padding-right: unset;
            vertical-align: unset;
            color: rgb(var(--byscuit-text-color));

            // Texte custom /!\ Vérifier styles de texte
            #c-txt {
                h2 { font-weight: 700; font-size: 28px; }
                h3 { font-weight: 600; font-size: 26px; }
                h4 { font-weight: 500; font-size: 24px; }
                strong { font-weight: bold; }
                sup { vertical-align: super; font-size: smaller; }
                sub { vertical-align: sub; font-size: smaller; }
                a {
                    display: inline;
                    border-bottom: unset;
                    box-shadow: inset 0 -0.1em 0 0 rgba(var(--byscuit-text-color), 0.5);
                    transition: color 300ms;

                    &:hover {
                        color: rgb(var(--byscuit-text-hover-color));
                    }
                }
            }
        }

        // Sous-container boutons
        #c-bns {
            display: flex;
            flex-direction: row;
            gap: 10px;

            @media screen and (max-width: 575px) {
                flex-direction: column;
            }

            // Boutons
            .c-bn {
                margin: unset;
                border-radius: unset;
                font-weight: 600;
                transition: all 300ms !important;

                @extend .c-button;
            }

            // Bouton personnaliser et refuser tout
            #c-s-bn, #c-t-bn {
                @extend .c-button--empty-blue-navy-alpha-30;
            }

            // Bouton accepter tout
            #c-p-bn {
                @extend .c-button--full-blue-navy;
            }
        }
    }
}

// =================================================
// -------------------- Sidebar --------------------
// =================================================

// Container sidebar
#s-inr {

    // Sous-container header (Titre + bouton x)
    #s-hdr {
        color: rgb(var(--byscuit-text-color));

        // Bouton x
        #s-c-bnc {
            #s-c-bn {
                background: unset;
                border-radius: 100%;
                border: 2px solid rgb(var(--byscuit-text-color));
                display: flex;
                align-items: center;
                justify-content: center;
                transition: opacity 300ms !important;

                &::before,
                &::after {
                    background: rgb(var(--byscuit-text-color));
                    left: unset;
                    top: unset;
                }

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    // Sous-container options
    #s-bl {
        padding-bottom: 4em;

        @media screen and (max-width: 688px) { padding-bottom: unset; }
        @media screen and (max-width: 575px) { padding-bottom: 7em; }

        // Items dans la liste
        .c-bl {
            margin-bottom: 0.5em;

            // Titre informationel
            .title {
                .b-tl {
                    color: rgb(var(--byscuit-text-color));
                }
            }

            // Texte custom /!\ Vérifier styles de texte
            .desc {
                .p {
                    color: rgb(var(--byscuit-text-color));

                    h2 { font-weight: 700; font-size: 28px; }
                    h3 { font-weight: 600; font-size: 26px; }
                    h4 { font-weight: 500; font-size: 24px; }
                    strong { font-weight: bold; }
                    sup { vertical-align: super; font-size: smaller; }
                    sub { vertical-align: sub; font-size: smaller; }
                    a {
                        display: inline;
                        border-bottom: unset;
                        box-shadow: inset 0 -0.1em 0 0 rgba(var(--byscuit-text-color), 0.5);
                        transition: color 300ms;

                        &:hover {
                            color: rgb(var(--byscuit-text-hover-color));
                        }
                    }
                }
            }
        }

        // Catégories cookies
        .b-ex {
            color: white;
            background: rgb(var(--byscuit-text-color));
            border-radius: unset;

            // Sous-container titre + toggle rubrique
            .b-bn {

                // Titre + chevron
                .b-tl {
                    color: white;
                    background: rgb(var(--byscuit-text-color));
                    background-color: rgb(var(--byscuit-text-color));
                    border-radius: unset;
                    transition: opacity 300ms;

                    &::before {
                        border: solid white;
                        border-width: 0 2px 2px 0;
                    }

                    &:hover {
                        opacity: 0.8;
                    }
                }

                // Toggle catégorie cookies
                .b-tg {
                    // Requis
                    &.c-ro {
                        right: 1.1em;
                    }

                    // Toggle off
                    .c-tg {
                        background: rgba(var(--byscuit-toggle-off-bg-color), 0.5);
                    }

                    // Toggle on
                    .c-tgl:checked~.c-tg {
                        background: rgb(var(--byscuit-toggle-on-bg-color));
                    }
                }
            }

            // Sous-container contenu rubrique
            .b-acc {
                border-radius: unset;
                background-color: #eeeeee;

                // Texte custom /!\ Vérifier styles de texte
                .p {
                    color: rgb(var(--byscuit-text-color));

                    h2 { font-weight: 700; font-size: 28px; }
                    h3 { font-weight: 600; font-size: 26px; }
                    h4 { font-weight: 500; font-size: 24px; }
                    strong { font-weight: bold; }
                    sup { vertical-align: super; font-size: smaller; }
                    sub { vertical-align: sub; font-size: smaller; }
                    a {
                        display: inline;
                        border-bottom: unset;
                        box-shadow: inset 0 -0.1em 0 0 rgba(var(--byscuit-text-color), 0.5);
                        transition: color 300ms;

                        &:hover {
                            color: rgb(var(--byscuit-text-hover-color));
                        }
                    }
                }

                // Bouton rubrique cookies catégorie
                .b-tb {
                    color: rgb(var(--byscuit-text-color));

                    &::before {
                        border: solid rgb(var(--byscuit-text-color));
                        border-width: 0 2px 2px 0;
                    }
                }

                // Contenu rubrique cookies catégorie
                .tb-c {
                    transition: font-size .12s ease, margin .12s ease, opacity .1s .15s ease;

                    tbody {
                        color: rgb(var(--byscuit-text-color));

                        // Détail de cookie
                        tr {
                            &:hover {
                                background-color: #eeeeee;
                            }

                            // Texte custom /!\ Vérifier styles de texte
                            td {
                                h2 { font-weight: 700; font-size: 28px; }
                                h3 { font-weight: 600; font-size: 26px; }
                                h4 { font-weight: 500; font-size: 24px; }
                                strong { font-weight: bold; }
                                sup { vertical-align: super; font-size: smaller; }
                                sub { vertical-align: sub; font-size: smaller; }
                                a {
                                    display: inline;
                                    border-bottom: unset;
                                    box-shadow: inset 0 -0.1em 0 0 rgba(var(--byscuit-text-color), 0.5);
                                    transition: color 300ms;

                                    &:hover {
                                        color: rgb(var(--byscuit-text-hover-color));
                                    }
                                }

                                // Titre ligne
                                &::before {
                                    color: rgb(var(--byscuit-text-color));
                                }
                            }
                        }
                    }
                }
            }

            // Contenu rubrique cookies catégorie caché + transition
            .b-acc.act {
                .tb-c {
                    background-color: #eeeeee;
                    font-size: 0;
                    opacity: 0;
                    margin: 0;
                    transition: opacity .1s ease, font-size .15s .15s ease, margin .15s .15s ease;

                    tbody {
                        // Détail de cookie
                        tr {
                            margin-bottom: 0;
                            transition: margin .3s .2s;
                        }
                    }
                }
            }
        }

        // Transition rubrique catégorie qui ferme
        .b-ex > .b-acc {
            display: block;
            font-size: 0;
            opacity: 0;
            margin: 0;
            max-height: unset;

            .b-tb {
                padding: 0 24px;
                transition: padding .3s .2s ease;

                &::before {
                    border-width: 0;
                    transition: border-width .3s .2s ease;
                }
            }

            tr {
                margin-bottom: 0;
                border-top: unset;
                transition: margin .3s .2s ease;
            }

            transition: opacity .2s ease, font-size .3s .2s ease, margin .3s .2s ease;
        }

        // Transition rubrique catégorie qui ouvre
        .b-ex.act > .b-acc {
            font-size: 15px;
            opacity: 1;
            margin: unset;

            .b-tb {
                padding: 10px 24px;
                transition: padding .3s ease;

                &::before {
                    border-width: 0 2px 2px 0;
                    transition: border-width .3s ease;
                }
            }

            tr {
                margin-bottom: 15px;
                border-top: 1px solid rgba(var(--byscuit-text-color), .6);
                transition: margin .3s ease;
            }

            transition: margin .3s ease, font-size .3s ease, opacity .2s .35s ease;
        }
    }

    // Sous-container boutons
    #s-bns {
        display: flex !important;
        height: fit-content;
        gap: 10px;

        @media screen and (max-width: 575px) {
            flex-direction: column;
        }

        // Boutons
        .c-bn {
            margin: unset;
            border-radius: unset;
            font-weight: 600;
            text-wrap: nowrap;
            justify-content: center;
            transition: all 300ms !important;

            @extend .c-button;
        }

        // Bouton sauvegarder
        #s-sv-bn {
            @extend .c-button--full-blue-navy;
        }

        // Bouton accepter tout et refuser tout
        #s-all-bn, #s-rall-bn {
            @extend .c-button--empty-blue-navy-alpha-30;
        }
    }
}

// ==================================================
// ------------------ Placeholders ------------------
// ==================================================

.placeholder_auto_div {
    background-color: #dcdcdc;
    padding: 5px;

    // Texte du placeholder
    .placeholder-txt {
        color: rgb(var(--byscuit-text-color));
        font-size: var(--fz-17);
        line-height: 1.15em;
        text-align: start;
        font-family: var(--ff);

        // Lien pour activer la catégories de cookies
        .linkStyle {
            font-weight: 500;
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--byscuit-text-color), 0.5);
            text-decoration: none;
            transition: color 300ms var(--out-cubic);

            &:hover {
                color: rgb(var(--byscuit-text-hover-color));
            }
        }
    }
}

// =================================================
// ----------------- Bouton sticky -----------------
// =================================================

.c-button-cookies {
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(var(--rgb-white));
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(var(--rgb-black), 0.25);
    transition: all 300ms;
    z-index: 109;

    &:hover {
        background-color: rgb(var(--byscuit-text-color));
        box-shadow: 0 0 10px rgba(var(--rgb-white), 0.35);
    }

    &__svg {
        width: 20px;
        height: 20px;
        fill: rgb(var(--byscuit-text-color));
        transition: fill 300ms;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-white));
    }
}
