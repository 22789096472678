.c-most-wanted-documents-list {
    --most-wanted-documents-list-gap-x: var(--x-40);
    --most-wanted-documents-list-gap-y: var(--y-40);
    --most-wanted-documents-list-nb-columns: 3;

    @media screen and (max-width: 1200px) {
        --most-wanted-documents-list-nb-columns: 2;
    }

    @media screen and (max-width: 800px) {
        --most-wanted-documents-list-gap-x: 0px;
        --most-wanted-documents-list-nb-columns: 1;
    }
}

.c-most-wanted-documents-list {
    padding-bottom: var(--y-60);
    border-bottom: 1px solid rgb(var(--rgb-blue-navy));

    &__container {
        width: 100%;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (var(--most-wanted-documents-list-gap-x)));
        margin-left: calc(0px - (var(--most-wanted-documents-list-gap-x) / 2));
        margin-right: calc(0px - (var(--most-wanted-documents-list-gap-x) / 2));
        margin-bottom: calc(0px - (var(--most-wanted-documents-list-gap-y)));
    }

    &__list-item {
        display: flex;
        justify-content: center;
        width: calc(100% / var(--most-wanted-documents-list-nb-columns));
        padding: 0 calc(var(--most-wanted-documents-list-gap-x) / 2);
        margin-bottom: var(--most-wanted-documents-list-gap-y);
    }

    &__list-item:nth-child(3) {
        @media screen and (max-width: 1200px) and (min-width: 801px) {
            display: none;
        }
    }
}
