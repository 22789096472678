.c-accordion-filter-by-dates {
    border-bottom: 1px solid rgb(var(--rgb-blue-navy), 0.1);

    &:first-child {
        border-top: 1px solid rgba(var(--rgb-blue-navy), 0.1);
    }

    &__header {
        position: relative;
    }

    &__header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 0 15px 31px;
    }

    &__text {
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__header-content:hover &__text {
        color: rgb(var(--rgb-blue-hover));
    }

    &__plus {
        position: relative;
        display: block;
        width: 10px;
        height: 10px;
        margin-left: 20px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 1.5px;
            background-color: rgb(var(--rgb-blue-navy));
            border-radius: 2px;
            opacity: 1;
            transition: background-color 300ms, opacity 300ms;
            will-change: transform;
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }

    &__header-content:hover &__plus::before,
    &__header-content:hover &__plus::after {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &.js-accordion-opened &__plus::after {
        opacity: 0;
    }

    &__checkbox-field {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        .c-checkbox-field__box {
            margin-top: 0;
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    &__checkboxes {
        padding: 10px 0 25px 31px;

        .c-checkbox-field__label {
            display: flex;
            align-items: center;
        }

        .c-checkbox-field__text {
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.3em;
            transition: color 300ms;
        }

        .c-checkbox-field__label:hover .c-checkbox-field__text {
            color: rgb(var(--rgb-blue-hover));
        }

        .c-checkbox-field__box {
            margin-top: 0;
        }
    }
}
