@use "../../base/typography";
@use "../../scopes/cms-content";

#jobillico-widget {
    // List - résumé
    #jobi-jobs-list li .resume {
        display: none !important;
    }

    // Détail - retour à la liste (premier)
    #jobi-expandOffer p:first-child {
        margin-bottom: var(--y-25);
    }

    // Détail - retour à la liste (dernier)
    #jobi-expandOffer p:last-child {
        margin-top: var(--y-25);
    }

    // Détail - retour à la liste lien
    #jobi-expandOffer p:first-child a,
    #jobi-expandOffer p:last-child a {
        @extend .c-button;
        @extend .c-button--empty-blue-navy-alpha-30;
        margin: 0 !important;
    }

    // Détail - titre
    #jobi-expandOffer .job_title {
        margin-bottom: var(--y-65);
    }

    // Détail - titre lien
    #jobi-expandOffer .job_title a {
        @extend .c-medium-title;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    // Détail - titre lien hover
    #jobi-expandOffer .job_title a:hover {
        color: rgb(var(--rgb-blue-hover));
    }

    // Détail - nom de l'entreprise
    #jobi-expandOffer .companyName {
        display: none;
    }

    // Détail - section
    #jobi-expandOffer .section {
        @extend .s-cms-content;
    }

    // Détail - section suivant une section
    #jobi-expandOffer .section + .section {
        margin-top: var(--y-65);
    }

    // Détail - titre de section
    #jobi-expandOffer .section .section_title {
        @extend .c-small-title;
        margin-top: var(--y-65);
        margin-bottom: var(--y-30);
    }

    #jobi-expandOffer .section .job_requirement {
        @extend .c-paragraph;
    }

    // Détail - bouton postuler
    #jobi-expandOffer .dark-geeen-btn {
        @extend .c-button;
        @extend .c-button--full-blue-navy;
        margin-top: var(--y-65);
    }

    // Pagination
    #jobi-paginate-widget {
        margin-top: var(--y-40);
    }

    // Pagination - list
    #jobi-paginate-widget .pagination {
        display: flex;
        align-items: center;
    }

    // Pagination - list item
    #jobi-paginate-widget .pagination li:not(:last-child) {
        margin-right: 6px;
    }

    // Pagination - lien
    #jobi-paginate-widget .pagination li a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        font-size: var(--fz-15);
        font-weight: 500;
        color: rgb(var(--rgb-blue-navy));
        transition: background-color 300ms, color 300ms;

        @media screen and (max-width: 500px) {
            width: 34px;
            height: 34px;
        }
    }

    // Pagination - lien hover
    #jobi-paginate-widget .pagination li a:hover {
        background-color: rgb(var(--rgb-grey-background));
    }

    // Pagination - lien actif
    #jobi-paginate-widget .pagination li a.inactive {
        background-color: rgb(var(--rgb-blue-hover));
        font-weight: 600;
        color: rgb(var(--rgb-white));
        pointer-events: none;
    }
}

#jobillico-propulsed {
    display: none;
}
