.c-organization-card {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 64px;
    padding: var(--y-nus-25) var(--x-nus-35);
    background-color: rgb(var(--rgb-grey-background));
    text-align: left;

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
    }

    &__title {
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));
    }

    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        background-color: rgb(var(--rgb-white));
        border-radius: 50%;
        transition: background-color 300ms;
    }

    &:hover &__svg-container {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__svg {
        width: 13px;
        height: 13px;
        stroke: rgb(var(--rgb-blue-navy));
        transition: stroke 300ms;
    }

    &:hover &__svg {
        stroke: rgb(var(--rgb-white));
    }
}
