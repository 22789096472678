.c-file-field {
    --file-field-color: rgb(var(--rgb-blue-navy));
    --file-field-color-hover: rgb(var(--rgb-white));
    --file-field-background-color: transparent;
    --file-field-background-color-hover: rgb(var(--rgb-blue-hover));
    --file-field-border-color: rgba(var(--rgb-blue-navy), 0.3);
    --file-field-border-color-hover: rgb(var(--rgb-blue-hover));
    --file-field-x-color: rgb(var(--rgb-blue-navy));
    --file-field-x-color-hover: rgb(var(--rgb-blue-hover));
}

.c-file-field {
    position: relative;

    &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: 13px 73px 13px 35px;
        background-color: var(--file-field-background-color);
        border: 1px solid var(--file-field-border-color);
        cursor: pointer;
        transition: background-color 300ms, border-color 300ms;
    }

    [data-file]:not([data-file=""]) &__label {
        pointer-events: none;
    }

    &__label:hover {
        background-color: var(--file-field-background-color-hover);
        border-color: var(--file-field-border-color);
    }

    &__text {
        display: block;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--file-field-color);
        word-wrap: break-word;
        overflow: hidden;
        transition: color 300ms;
    }

    &__label:hover &__text {
        color: var(--file-field-color-hover);
    }

    &__svg {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 50%;
        right: 33px;
        stroke: var(--file-field-color);
        transform: translate(0,-50%);
        transition: fill 300ms, opacity 300ms;
    }

    &__label:hover &__svg {
        stroke: var(--file-field-color-hover);
    }

    [data-file]:not([data-file=""]) &__svg {
        opacity: 0;
    }

    &__x {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        right: 33px;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transform: translate(0, -50%);
        transition: opacity 300ms, visibility 0ms ease 300ms;
    }

    &__x::before,
    &__x::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        top: 50%;
        left: 50%;
        background-color: var(--file-field-x-color);
        transition: background-color 300ms;
    }

    &__x::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &__x::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &__x:hover::before,
    &__x:hover::after {
        background-color: var(--file-field-x-color-hover);
    }

    [data-file]:not([data-file=""]) &__x {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
}
