@use "../../base/typography";

.c-anchor-card {
    padding: var(--y-nus-40) var(--x-nus-30);
    background-color: rgb(var(--rgb-blue-navy));
    width: 100%;

    &__title {
        font-size: var(--fz-17);
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__link {
        @extend .c-underlined-link-white;
        display: block;
        margin-top: var(--y-nus-20);
    }
}
