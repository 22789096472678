@use "../../base/typography";

.c-call-to-action-aside-card {
    --call-to-action-aside-card-image-ratio: 100%; // 280/280
}

.c-call-to-action-aside-card {
    display: flex;
    flex-direction: column;
    width: 100%;

    // Picture container
    &__picture-container {
        position: relative;
    }

    &__picture {
        position: relative;
        padding-bottom: var(--call-to-action-aside-card-image-ratio);
    }

    &__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // Content
    &__content {
        display: flex;
        flex-direction: column;
        padding: var(--y-nus-40) var(--x-nus-30);
        background-color: rgb(var(--rgb-blue-navy));
    }

    &__title {
        font-size: var(--fz-17);
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__link {
        @extend .c-underlined-link-white;
        display: block;
        margin-top: var(--y-nus-20);
    }
}
