.c-overlay-menu-desktop {
    --overlay-menu-desktop-section-left-width: 280px;
    --overlay-menu-desktop-section-right-width: 300px;
}

.c-overlay-menu-desktop {
    position: fixed;
    top: var(--alerts-height);
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    transition: top 500ms var(--in-out-cubic);
    z-index: 110;

    &__container {
        position: relative;
        top: var(--header-top-height);
        left: var(--x-60);
        width: calc(100% - var(--x-60) - var(--x-60));
        padding-top: var(--header-bottom-height);
        background-color: rgb(var(--rgb-white));
        overflow: hidden;
        transition: box-shadow 300ms;

        // Ce before est là pour éviter un flash blanc quand on change de section
        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: var(--overlay-menu-desktop-section-left-width);
            background-color: rgb(var(--rgb-grey-background));
        }

        // Ce after est là pour éviter un flash blanc quand on change de section
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: var(--overlay-menu-desktop-section-right-width);
            background-color: rgb(var(--rgb-blue-navy));
        }
    }

    // Sections
    &__sections {
        position: relative;
        height: 0px;
        transition: height 800ms var(--in-out-quart);
    }

    // Section
    &__section {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        height: 500px;
        max-height: calc((var(--vh, 1vh) * 100) - var(--header-height) - var(--header-top-height));
        opacity: 0;
        pointer-events: none;
        transition: opacity 600ms 0ms;
    }

    // Section left
    &__section-left {
        width: var(--overlay-menu-desktop-section-left-width);
        background-color: rgb(var(--rgb-grey-background));

        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-grey-background));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #6B7D8F;
        }
    }

    // Section left - sub sections list
    &__sub-sections-list-item &__sub-section {
        border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.1);
    }

    &__sub-sections-list-item:first-child &__sub-section {
        border-top: 1px solid rgba(var(--rgb-blue-navy), 0.1);
    }

    &__sub-section {
        position: relative;
        display: block;
        width: 100%;
        padding: 24px 30px;
        background-color: rgb(var(--rgb-grey-background));
        font-size: var(--fz-16);
        font-weight: 500;
        line-height: 1.4em;
        text-align: left;
        color: rgb(var(--rgb-grey-note));
        transition: background-color 300ms, color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 5px;
            background: rgb(var(--rgb-red));
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    &__sub-section:hover,
    &__sub-section.is-active {
        color: rgb(var(--rgb-blue-navy));

        &::before {
            opacity: 1;
        }
    }

    &__sub-section.is-active {
        background-color: rgb(var(--rgb-white));
        pointer-events: none;
    }

    // Section middle
    &__section-middle {
        flex: 1;
    }

    // Section middle - sub section container
    &__sub-section-container{
        position: relative;
        height: 100%;
    }

    &__sub-section-under-container {
        position: absolute;
        height: 100%;
        width: 100%;
        padding: var(--y-nus-30) var(--x-nus-60);
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms;

        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-white));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-grey-scrollbar-thumb));
        }
    }

    // Section middle - sub section links list
    // Note : on mets les liens sous deux colonnes si il y plus de 5 liens
    &__sub-section-links-list {
        max-width: 700px;
    }

    &__sub-section-links-list--more-than-9-links {
        column-count: 2;
        margin-right: calc(0px - var(--x-nus-60));
        margin-bottom: calc(0px - (var(--y-nus-20) / 2));
    }

    &__sub-section-links-list-item {
        margin-right: var(--x-nus-60);
        margin-bottom: var(--y-nus-20);
    }

    &__sub-section-link {
        font-size: var(--fz-14);
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;
    }

    &__sub-section-link:hover {
        color: rgb(var(--rgb-red));
    }

    &__section-right {
        width: var(--overlay-menu-desktop-section-right-width);
        background-color: rgb(var(--rgb-blue-navy));

        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: rgb(var(--rgb-blue-navy));
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgb(var(--rgb-grey-scrollbar-thumb));
        }
    }

    // Section right - call to action menu
    &__call-to-action-menu {
        width: 100%;
        height: 100%;
    }

    // Section right - most requested
    &__most-requested {
        width: 100%;
        padding: var(--y-nus-30) var(--x-nus-30);
        background-color: rgb(var(--rgb-blue-navy));
    }

    &__most-requested-title {
        font-size: var(--fz-16);
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__most-requested-links-list {
        margin-top: var(--y-nus-30);
    }

    &__most-requested-links-list-item:not(:last-child) {
        margin-bottom: var(--y-nus-20);
    }

    &__most-requested-link {
        font-size: var(--fz-14);
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        transition: opacity 300ms;
    }

    &__most-requested-link:hover {
        opacity: 0.6;
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin sectionMenuDesktop($section) {
    .show-overlay-menu-desktop:not(.closing-overlay-menu-desktop)[data-section="#{$section}"],
    .show-overlay-menu-desktop.closing-overlay-menu-desktop[data-section-closing="#{$section}"] {
        .c-overlay-menu-desktop [data-section="#{$section}"] {
            pointer-events: all;
            opacity: 1;
            transition: opacity 600ms 300ms;
            z-index: 1;
        }

        .c-header__main-navigation[data-section="#{$section}"] {
            color: rgb(var(--rgb-blue-hover));
            &::after { opacity: 1; }
        }
    }
}

@include sectionMenuDesktop(ville);
@include sectionMenuDesktop(services-aux-citoyens);
@include sectionMenuDesktop(taxes-permis-et-reglements);
@include sectionMenuDesktop(loisirs-culture-et-vie-communautaire);


/*
|--------------------------------------------------------------------------
| SUBSECTION VISIBLE & FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu-desktop {
    .c-overlay-menu-desktop {
        &__sub-section-under-container[data-sub-section].is-show {
            pointer-events: all;
            opacity: 1;
            transition: opacity 600ms ease;
            z-index: 1;
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-menu-desktop {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-menu-desktop {
        visibility: visible;
        pointer-events: all;

        &__container {
            box-shadow: 0 57px 70px rgba(4, 22, 43, 0.4);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu-desktop.closing-overlay-menu-desktop {
    body {
        --alerts-height: 0px;
    }

    .c-overlay-menu-desktop {
        &__container {
            box-shadow: 0 57px 70px rgba(4, 22, 43, 0);
            transition: box-shadow 300ms 500ms;
        }
    }
}
