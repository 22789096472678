@use "../../abstract/functions" as *;
@use "../../abstract/placeholders";

.c-home-fast-search {
    --home-fast-search-birds-decor-svg-width: 937px;

    @media screen and (max-width: 800px) {
        --home-fast-search-birds-decor-svg-width: 750px;
    }
}

.c-home-fast-search {
    position: relative;
    padding: var(--y-nus-90) var(--x-nus-70) var(--y-nus-80);
    background-color: rgb(var(--rgb-blue-navy));
    overflow: hidden;
    width: 100%;
    z-index: 0;

    // Search field and suggestions links
    &__search-field-surtitle,
    &__suggestions-links-surtitle {
        display: block;
        font-size: var(--fz-13);
        font-weight: 600;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgb(var(--rgb-white));
    }

    // Search field
    &__search-field-surtitle {
        margin-bottom: 10px;
    }

    &__search-field-title {
        display: block;
        font-size: var(--fz-39);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        margin-bottom: var(--y-nus-25);
    }

    &__search-field {
        .c-search-field__input-container {
            position: relative;
        }

        .c-search-field__input {
            font-family: var(--ff);
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.3em;
            color: rgba(var(--rgb-blue-navy), 0.8);
            width: 100%;
            padding: 15px 90px 15px 30px;
            background-color: rgb(var(--rgb-white));
            border: 0;
            box-shadow: 0 0 0 100px rgb(var(--rgb-white)) inset;
            overflow: hidden;
            transition: color 300ms;

            @media screen and (max-width: 600px) {
                padding: 15px 80px 15px 20px;
            }

            &::-webkit-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.8); }
            &::-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.8); }
            &:-ms-input-placeholder { color: rgba(var(--rgb-blue-navy), 0.8); }
            &:-moz-placeholder { color: rgba(var(--rgb-blue-navy), 0.8); }
            &::placeholder { color: rgba(var(--rgb-blue-navy), 0.8); }
        }

        .c-search-field__label {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            font-family: var(--ff);
            font-size: var(--fz-15);
            font-weight: 500;
            line-height: 1.3em;
            cursor: text;
            pointer-events: none;
            padding: 15px 90px 15px 30px;

            @media screen and (max-width: 600px) {
                padding: 15px 80px 15px 20px;
            }
        }

        .c-search-field__input:focus + .c-search-field__label,
        .c-search-field__input:not(:placeholder-shown) + .c-search-field__label {
            display: none !important;
        }

        .c-search-field__button-delete {
            position: absolute;
            right: 60px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            opacity: 0;
            pointer-events: none;
            transition: opacity 300ms;

            @media screen and (max-width: 600px) {
                right: 50px;
            }

            // Note : ce before sert à augmenter la zone de touch du bouton
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
            }
        }

        .c-search-field__input:not(:placeholder-shown) ~ .c-search-field__button-delete {
            opacity: 1;
            pointer-events: all;
        }

        .c-search-field__button-delete-x {
            @extend %x;
            --x-size: 15px;
            --x-thickness: 2px;
            --x-color: rgb(var(--rgb-blue-navy));
            --x-border-radius: 2px;
        }

        .c-search-field__button-delete:hover .c-search-field__button-delete-x {
            --x-color: rgb(var(--rgb-blue-hover));
        }

        .c-search-field__button-submit {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;

            @media screen and (max-width: 600px) {
                right: 20px;
            }

            // Note : ce before sert à augmenter la zone de touch du bouton
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
            }
        }

        .c-search-field__button-submit-svg {
            width: 15px;
            height: 15px;
            stroke: rgb(var(--rgb-blue-navy));
            transition: stroke 300ms;
        }

        .c-search-field__button-submit:hover .c-search-field__button-submit-svg {
            stroke: rgb(var(--rgb-blue-hover));
        }
    }


    // Suggestions links
    &__suggestions-links-surtitle {
        margin-top: var(--y-nus-50);
        margin-bottom: var(--y-nus-25);
    }

    &__suggestions-links-list {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
    }

    &__suggestions-links-list-item {
        @at-root [data-zoom-font-size="80"] &:nth-child(n+4) {
            @media screen and (min-width: 801px) {
                display: none;
            }
        }

        @at-root [data-zoom-font-size="70"] &:nth-child(n+6) {
            @media screen and (min-width: 801px) {
                display: none;
            }
        }
    }

    &__suggestion-link {
        @extend %tag;
        --tag-background-color: rgb(19, 68, 123); // #13447B
        --tag-font-weight: 500;
        --tag-color: rgb(var(--rgb-white));
    }

    &__suggestion-link:hover {
        --tag-background-color: rgb(var(--rgb-blue-hover));
    }

    // Birds decor
    &__birds-decor {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        z-index: -1;
    }

    &__birds-decor-svg {
        position: absolute;
        top: 75px;
        right: clamp(0px, responsive-size(800px, 1440px, 0px, 80px), 80px);
        fill: rgba(var(--rgb-blue-dark-navy), 0.5);
        width: var(--home-fast-search-birds-decor-svg-width);
        height: calc(var(--home-fast-search-birds-decor-svg-width) * 1.0427); // 977/937

        @media screen and (max-width: 800px) {
            top: 45px;
            right: clamp(-20px, responsive-size(600px, 800px, -20px, 80px), 80px);
        }
    }
}
