@use "../../abstract/placeholders";
@use "../../base/typography";

.c-overlay-popup {
    --overlay-popup-width: 520px;
    --overlay-popup-padding: 80px 40px;

    @media screen and (max-width: 1200px) {
        --overlay-popup-padding: 80px 30px;
    }

    @media screen and (max-width: 1024px) {
        --overlay-popup-padding: 50px 22px;
    }
}

.c-overlay-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 120;

    // Container
    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--overlay-popup-width);
        max-width: calc(100% - (var(--x-40) * 2));
        max-height: calc(var(--vh, 1vh) * 90);
        padding: var(--overlay-popup-padding);
        background-color: rgb(var(--rgb-white));
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: transform 800ms var(--in-out-quart), opacity 800ms var(--in-out-quart);

        &--green {
            border-left: 5px solid rgb(61, 140, 32); // #3D8C20
        }
        &--yellow {
            border-left: 5px solid rgb(255, 210, 62); // #FFD23E
        }
        &--red {
            border-left: 5px solid rgb(197, 23, 56); // #C51738
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        @extend .c-medium-title;
        text-align: center;
        margin-bottom: 20px;
    }

    &__button-close {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
    }

    &__button-close-x {
        @extend %x;
        --x-size: 15px;
        --x-thickness: 2px;
        --x-color: rgb(var(--rgb-blue-navy));
        --x-border-radius: 2px;
    }

    &__button-close:hover &__button-close-x {
        --x-color: rgb(var(--rgb-blue-hover));
    }

    &__text {
        text-align: center;
    }

    &__button {
        @extend .c-button;
        @extend .c-button--full-blue-navy;
        margin-top: var(--y-nus-40);
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 500ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-popup {
    .c-overlay-popup {
        visibility: visible;
        pointer-events: all;

        &__container {
            opacity: 1;
            transform: translate(-50%, -50%);
            pointer-events: all;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.25);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-popup.closing-overlay-popup {
    .c-overlay-popup {
        &__container {
            opacity: 0;
            transform: translate(-50%, -40%);
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0);
        }
    }
}
