.o-layout {
    display: flow-root;
    position: relative;


    // Section spécifique à une page
    &--page-home {
        .o-section--home-fast-search-and-home-banner {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
        }

        .o-section--home-events {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-bottom: var(--y-50);
        }

        .o-section--home-dates-to-remember {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-50);
            margin-bottom: var(--y-50);
        }

        .o-section--home-fast-links {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: max(var(--y-120), 70px);
            margin-bottom: max(var(--y-120), 70px);
        }

        .o-section--home-showcase {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: max(var(--y-120), 70px);
            margin-bottom: max(var(--y-120), 70px);
        }

        .o-section--home-publications {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: max(var(--y-120), 70px);
            margin-bottom: max(var(--y-120), 70px);
        }


        // Section suivant une section
        .o-section--home-fast-search-and-home-banner + .o-section {
            margin-top: 0;
        }
    }


    // Section spécifique à une page
    &--page-events {
        .o-section--filter-swiper-and-buttons {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-30);
            margin-bottom: var(--y-50);
        }

        .o-section--events {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-50);
            margin-bottom: var(--y-90);
        }

        .o-section--pagination-list-pages {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-90);
            margin-bottom: var(--y-120);
        }
    }


    // Section spécifique à une page
    &--page-publications,
    &--page-archives {
        .o-section--filter-swiper-and-buttons {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-30);
            margin-bottom: var(--y-50);
        }

        .o-section--publications {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-50);
            margin-bottom: var(--y-90);
        }

        .o-section--pagination-list-pages {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-90);
            margin-bottom: var(--y-120);
        }
    }


    // Section spécifique à une page
    &--page-directory-of-organizations {
        .o-section--search-filter-and-sort-or-filter-selects {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-60);
            margin-bottom: var(--y-65);
        }

        .o-section--rubrics {
            margin-top: var(--y-60);
        }
    }


    // Section spécifique à une page
    &--page-directory-of-regulations-and-zoning {
        .o-section--most-wanted-documents {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-65);
            margin-bottom: var(--y-60);
        }

        .o-section--search-filter-and-sort-or-filter-selects {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-60);
            margin-bottom: var(--y-65);
        }

        .o-section--documents {
            margin-top: var(--y-65);
            margin-bottom: var(--y-85);
        }

        .o-section--documents .c-section-footer {
            @media screen and (min-width: 601px) {
                display: none;
            }
        }

        .o-section--pagination-list-pages {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-90);
            margin-bottom: var(--y-120);
        }
    }


    // Section spécifique à une page
    &--page-dates-to-remember {
        .o-section--dates-to-remember {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-60);
            margin-bottom: var(--y-65);

            &:last-of-type {
                margin-bottom: var(--y-90);
            }
        }

        .o-section--pagination-list-pages {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-90);
            margin-bottom: var(--y-120);
        }
    }


    // Section spécifique à une page
    &--page-section {
        .o-section--pages {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-60);
            margin-bottom: var(--y-120);
        }
    }


    // Section spécifique à une page
    &--page-search {
        .o-section--search-filter-and-filter-swiper {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-60);
            margin-bottom: var(--y-65);
        }

        .o-section--search-results {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-65);
        }

        .o-section--pagination-list-pages {
            padding-right: var(--x-60);
            padding-left: var(--x-60);
            margin-top: var(--y-90);
            margin-bottom: var(--y-120);
        }
    }


    // Section spécifique à une page
    &--page-contact {
        .o-section--contact-infos {
            margin-top: var(--y-30);
            margin-bottom: var(--y-65);

            @media screen and (max-width: 1000px) {
                margin-bottom: var(--y-30);
            }
        }

        .o-section--aside-mobile {
            margin-top: var(--y-30);
            margin-bottom: var(--y-65);
        }
    }
}
