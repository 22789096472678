@use "../../base/typography";

.c-contact-infos {
    &__list {
        display: flex;

        @media screen and (max-width: 1200px) and (min-width: 1001px),
        screen and (max-width: 800px) {
            flex-direction: column;
        }
    }

    &__list-item {
        flex: 1;
    }

    &__list-item:not(:last-child) {
        margin-right: var(--x-40);

        @media screen and (max-width: 1200px) and (min-width: 1001px),
        screen and (max-width: 800px) {
            margin-right: 0;
            margin-bottom: var(--y-30);

        }
    }

    &__info {
        width: 100%;
        height: 100%;
        padding: var(--y-nus-30) var(--x-nus-40);
        background-color: rgb(var(--rgb-grey-background));
    }

    &__info-title {
        display: block;
        font-size: var(--fz-20);
        font-weight: 600;
        line-height: 1.5em;
        margin-bottom: var(--y-nus-20);
    }

    &__info-subtitle {
        display: block;
        font-size: var(--fz-17);
        font-weight: 600;
        line-height: 1.5em;
        margin-bottom: var(--y-nus-20);
    }

    &__info-text {
        font-size: var(--fz-15);
        line-height: 1.5em;

        > a {
            @extend .c-underlined-link-blue-navy;
        }
    }

    // info with icon links
    &__info-with-icon-links {
        margin-top: var(--y-nus-28);
    }

    &__info-with-icon-link {
        display: flex;
        align-items: center;
    }

    &__info-with-icon-link:not(:last-child) {
        margin-bottom: 15px;
    }

    &__info-with-icon-link-svg {
        flex-shrink: 0;
        width: 17px;
        height: 17px;
        stroke: rgb(var(--rgb-blue-navy));
        margin-right: var(--x-nus-20);
    }

    &__info-with-icon-link-link {
        @extend .c-underlined-link-blue-navy;
    }

    // info links
    &__info-links {
        margin-top: var(--y-nus-28);
    }

    &__info-link {
        @extend .c-underlined-link-blue-navy;
    }

    &__info-link:not(:last-child) {
        margin-right: var(--x-nus-20);
    }
}
