@use "sass:math";

:root {
    // Générales
    --calendar-header-background-color: rgb(var(--rgb-white));
    --calendar-header-color: rgb(var(--rgb-blue-navy));

    --calendar-main-background-color: rgb(var(--rgb-white));
    --calendar-main-color: rgb(var(--rgb-blue-navy));
    --calendar-main-day-cell-size: 4.6rem;
    --calendar-main-day-cell-gap-heigth: 18px;
    --calendar-main-day-and-dow-cell-font-size: 1.7rem;
    --calendar-main-month-year-cell-size: calc(var(--calendar-main-day-cell-size) * 1.7);
    --calendar-main-month-year-cell-font-size: 1.7rem;


    // Dates des mois précédents ou suivant (.prev, .next)
    --calendar-day-previous-or-next-color: rgba(var(--rgb-grey-note), 0.5);
    --calendar-day-previous-or-next-background-color: transparent;
    --calendar-day-previous-or-next-border-color: transparent;
    --calendar-day-previous-or-next-dot-or-underline-background-color: rgba(var(--rgb-grey-note), 0.5);
    --calendar-month-year-previous-or-next-color: rgba(var(--rgb-grey-note), 0.5);


    // Date avec événement (:not(.disabled))
    --calendar-day-color: rgb(var(--rgb-blue-navy));
    --calendar-day-background-color: transparent;
    --calendar-day-border-color: transparent;
    --calendar-day-dot-or-underline-background-color: rgb(var(--rgb-blue-hover));
    --calendar-month-year-color: rgb(var(--rgb-blue-navy));

    --calendar-day-color-hover: rgb(var(--rgb-blue-navy));
    --calendar-day-background-color-hover: rgb(var(--rgb-grey-background));
    --calendar-day-border-color-hover: rgb(var(--rgb-grey-background));
    --calendar-day-dot-or-underline-background-color-hover: rgb(var(--rgb-blue-hover));
    --calendar-month-year-color-hover: rgb(var(--rgb-blue-hover));


    // Date d'aujourd'hui
    --calendar-today-color: rgb(var(--rgb-blue-navy));
    --calendar-today-background-color: transparent;
    --calendar-today-border-color: rgba(var(--rgb-blue-navy), 0.3);
    --calendar-today-dot-or-underline-background-color: rgb(var(--rgb-blue-hover));

    --calendar-today-color-hover: rgb(var(--rgb-blue-navy));
    --calendar-today-background-color-hover: rgb(var(--rgb-grey-background));
    --calendar-today-border-color-hover: rgb(var(--rgb-grey-background));
    --calendar-today-dot-or-underline-background-color-hover: rgb(var(--rgb-blue-hover));


    // Date sélectionnée
    --calendar-day-active-color: rgb(var(--rgb-white));
    --calendar-day-active-background-color: rgb(var(--rgb-blue-hover));
    --calendar-day-active-border-color: rgb(var(--rgb-blue-hover));
    --calendar-day-active-dot-or-underline-background-color: rgb(var(--rgb-white));

    --calendar-day-active-color-hover: rgb(var(--rgb-white));
    --calendar-day-active-background-color-hover: rgb(var(--rgb-blue-hover));
    --calendar-day-active-border-color-hover: rgb(var(--rgb-blue-hover));
    --calendar-day-active-dot-or-underline-background-color-hover: rgb(var(--rgb-white));
}

// Calendrier dans l'overlay calendar
.c-calendar {
    width: 100%;
    max-width: 418px;

    @media screen and (max-width: 500px),
    screen and (max-height: 800px) {
        --calendar-main-day-cell-size: 4rem;
        --calendar-main-day-cell-gap-heigth: 10px;
        max-width: 380px;

        .datepicker-picker .datepicker-header {
            padding: 0 12px 20px;
        }

        .datepicker-picker .datepicker-main {
            padding-top: 25px;
        }

        .datepicker-view .days-of-week {
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 400px),
    screen and (max-height: 700px) {
        --calendar-main-day-cell-size: 3.5rem;
        --calendar-main-day-and-dow-cell-font-size: 1.5rem;
        --calendar-main-month-year-cell-font-size: 1.5rem;

        .datepicker-cell.day .day-square::after {
            width: 18px;
        }
    }

    @media screen and (max-width: 350px) {
        --calendar-main-day-cell-size: 3rem;
    }
}

// Calendrier en popup dans champ date
.datepicker-dropdown {
    position: absolute;
    top: calc(100% + 15px) !important;
    left: 0 !important;
    width: 350px;
    background-color: white;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 300ms, visibility 0ms ease 300ms;
    z-index: 100;
    --calendar-main-day-cell-size: 3.5rem;
    --calendar-main-day-cell-gap-heigth: 10px;
    --calendar-main-day-and-dow-cell-font-size: 1.5rem;
    --calendar-main-month-year-cell-font-size: 1.5rem;

    @media screen and (max-width: 1100px) and (min-width: 1001px) {
        max-width: 325px;
    }

    @media screen and (max-width: 425px) {
        width: 100%;
    }

    @media screen and (max-width: 350px) {
        --calendar-main-day-cell-size: 3rem;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: opacity 300ms;
    }

    .datepicker-picker .datepicker-header {
        padding: 25px 24px;

        @media screen and (max-width: 350px) {
            padding: 25px 18px;
        }
    }

    .datepicker-picker .datepicker-main {
        padding: 25px 12px 10px;
    }

    .datepicker-view .days-of-week {
        margin-bottom: 10px;
    }

    .datepicker-cell.day .day-square::after {
        display: none;
    }

    .datepicker-view.months,
    .datepicker-view.years {
        margin-top: -14px;
    }
}

.datepicker-picker {
    display: inline-block;
    width: 100%;

    span {
        display: block;
        flex: 1;
        border: 0;
        cursor: default;
        text-align: center;
        -webkit-touch-callout: none;
        user-select: none;
    }

    .datepicker-header {
        position: relative;
        background-color: var(--calendar-header-background-color);
        padding: 0 20px 25px;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: rgb(var(--rgb-blue-navy));
        }
    }

    .datepicker-main {
        background-color: var(--calendar-main-background-color);
        padding-top: 42px;
    }
}

.datepicker-controls {
    display: flex;
    align-items: center;

    .button {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        font-family: var(--ff);
        font-size: min(var(--fz-25), 2.5rem);
        font-weight: 600;
        line-height: 1.3em;
        color: var(--calendar-header-color);

        &:focus,
        &:active {
            outline: none;
        }

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-btn,
    .next-btn {
        width: 25px;
        height: 25px;
        background-color: rgb(var(--rgb-grey-background));
        border-radius: 50%;
        overflow: hidden;
        transition: background-color 300ms;

        > svg {
            width: 11px;
            height: 6px;
            stroke: rgb(var(--rgb-blue-navy));
            transition: stroke 300ms;
        }

        &.disabled {
            visibility: hidden;
        }
    }

    .prev-btn > svg {
        transform: rotate(90deg);
    }

    .next-btn > svg {
        transform: rotate(-90deg);
    }

    .prev-btn:hover,
    .next-btn:hover {
        background-color: rgb(var(--rgb-blue-hover));

        > svg {
            stroke: rgb(var(--rgb-white));
        }
    }
}

.datepicker-view {
    display: flex;

    .days-of-week {
        display: flex;
        margin-bottom: 18px;
    }

    .dow {
        flex-basis: percentage(math.div(1, 7));
        font-size: var(--calendar-main-day-and-dow-cell-font-size);
        font-weight: 600;
        line-height: 1.6em;
        color: var(--calendar-main-color);
    }

    .days {
        width: 100%;
    }
}

.datepicker-grid {
    display: flex;
    flex-wrap: wrap;
}

.datepicker-cell {
    &.day {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.day .day-square-container-parent {
        flex: none;
        width: min(var(--calendar-main-day-cell-size), 100%);
    }

    &.day .day-square-container {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
    }

    &.day .day-square {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--calendar-main-day-and-dow-cell-font-size);
        font-weight: 500;
        line-height: 1.6em;
        color: var(--calendar-main-color);
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: transparent;
            border: 1px solid transparent;
            transition: background-color 300ms, border-color 300ms;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: calc(50% - ((var(--calendar-main-day-and-dow-cell-font-size) * 1.6) / 2));
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 1px;
            background-color: transparent;
            transition: background-color 300ms;
            z-index: 1;
        }
    }

    &.month,
    &.year,
    &.decade {
        font-size: var(--calendar-main-month-year-cell-font-size);
        font-weight: 500;
        line-height: 1.6em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: var(--calendar-main-color);
    }

    &.disabled .day-square {
        cursor: default;
        pointer-events: none;

        &::after {
            display: none;
        }
    }

    // Date avec événement (:not(.disabled))
    &:not(.disabled) .day-square {
        color: var(--calendar-day-color);
        cursor: pointer;
        transition: color 300ms;

        &::before {
            background-color: var(--calendar-day-background-color);
            border-color: var(--calendar-day-border-color);
        }

        &::after {
            background-color: var(--calendar-day-dot-or-underline-background-color);
        }

        &:hover {
            color: var(--calendar-day-color-hover);

            &::before {
                background-color: var(--calendar-day-background-color-hover);
                border-color: var(--calendar-day-border-color-hover);
            }

            &::after {
                background-color: var(--calendar-day-dot-or-underline-background-color-hover);
            }
        }
    }

    // Date d'aujourd'hui
    &.day.today:not(.selected) .day-square {
        color: var(--calendar-today-color);

        &::before {
            background-color: var(--calendar-today-background-color);
            border-color: var(--calendar-today-border-color);
        }

        &::after {
            background-color: var(--calendar-today-dot-or-underline-background-color);
        }

        &:hover:not(.disabled) {
            color: var(--calendar-today-color-hover);

            &::before {
                background-color: var(--calendar-today-background-color-hover);
                border-color: var(--calendar-today-border-color-hover);
            }

            &::after {
                background-color: var(--calendar-today-dot-or-underline-background-color-hover);
            }
        }
    }

    // Date sélectionnée
    &.day.selected .day-square {
        color: var(--calendar-day-active-color);

        &::before {
            background-color: var(--calendar-day-active-background-color);
            border-color: var(--calendar-day-active-border-color);
        }

        &::after {
            background-color: var(--calendar-day-active-dot-or-underline-background-color);
        }

        &:hover {
            color: var(--calendar-day-active-color-hover);

            &::before {
                background-color: var(--calendar-day-active-background-color-hover);
                border-color: var(--calendar-day-active-border-color-hover);
            }

            &::after {
                background-color: var(--calendar-day-active-dot-or-underline-background-color-hover);
            }
        }
    }

    // Dates des mois précédents ou suivant (.prev, .next)
    &.day.prev .day-square,
    &.day.next .day-square {
        color: var(--calendar-day-previous-or-next-color);

        &::before {
            background-color: var(--calendar-day-previous-or-next-background-color);
            border-color: var(--calendar-day-previous-or-next-border-color);
        }

        &::after {
            background-color: var(--calendar-day-previous-or-next-dot-or-underline-background-color);
        }
    }

    &.month,
    &.year,
    &.decade {
        color: var(--calendar-month-year-color);
        cursor: pointer;
        transition: color 300ms;

        &.prev,
        &.next {
            color: var(--calendar-month-year-previous-or-next-color);
        }

        &.selected,
        &:hover {
            color: var(--calendar-month-year-color-hover);
        }
    }
}

.datepicker-view {
    .days .datepicker-cell {
        flex-basis: percentage(math.div(1, 7));
        height: calc(var(--calendar-main-day-cell-size) + var(--calendar-main-day-cell-gap-heigth));
    }

    &.months .datepicker-cell,
    &.years .datepicker-cell,
    &.decades .datepicker-cell {
        flex-basis: 25%;
        height: var(--calendar-main-month-year-cell-size);
        line-height: var(--calendar-main-month-year-cell-size);
    }
}
