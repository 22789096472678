@use "../../abstract/placeholders";

.c-fast-result-card {
    display: flex;
    align-items: center;
    padding: var(--y-nus-30) 0;
    border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.3);

    @at-root .c-overlay-search__fast-results-list-item:first-child & {
        border-top: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: normal;
    }

    &__category-container {
        margin-right: var(--x-nus-40);

        @media screen and (max-width: 600px) {
            width: auto !important;
            margin-right: 0;
        }
    }

    &__category {
        @extend %tag;

        @media screen and (max-width: 600px) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    &__title {
        font-size: var(--fz-20);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;

        > mark {
            color: rgb(var(--rgb-red));
        }
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));

        > mark {
            color: rgb(var(--rgb-blue-hover));
        }
    }
}
