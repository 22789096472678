@use "../../base/typography";

.c-event-details {
    &__header {
        padding: var(--y-nus-25) var(--x-nus-30);
        background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
    }

    &__title {
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
    }

    &__content {
        padding: var(--y-nus-40) var(--x-nus-30);
        background-color: rgb(var(--rgb-grey-background));
    }

    &__detail-date,
    &__detail-location,
    &__detail-hour,
    &__detail-cost {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__detail-date-svg,
    &__detail-location-svg,
    &__detail-hour-svg,
    &__detail-cost-svg {
        flex-shrink: 0;
        width: 17px;
        height: 17px;
        stroke: rgb(var(--rgb-blue-navy));
        margin-right: var(--x-nus-20);
    }

    &__detail-date-text,
    &__detail-location-text,
    &__detail-hour-text,
    &__detail-cost-text {
        flex: 1;
        font-size: var(--fz-14);
        line-height: 1.3em;
        color: rgb(var(--rgb-blue-navy));
    }

    &__detail-date-text {
        font-weight: 600;
    }

    &__detail-location-link {
        @extend .c-underlined-link-blue-navy;
    }
}
