@use "../../base/typography";

.c-checkbox-field {
    --checkbox-field-label-color: rgb(var(--rgb-blue-navy));
    --checkbox-field-label-font-size: var(--fz-16);
    --checkbox-field-box-background-color: transparent;
    --checkbox-field-box-background-color-active: rgb(var(--rgb-blue-hover));
    --checkbox-field-box-border-color: rgba(var(--rgb-blue-navy), 0.5);
    --checkbox-field-box-border-color-active: rgb(var(--rgb-blue-hover));
}

.c-checkbox-field {
    position: relative;

    &__header {
        margin-bottom: 15px;

        .c-form-note {
            padding-bottom: 15px;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__label {
        display: flex;
        position: relative;
        margin-bottom: 15px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        &:last-child {
            margin-bottom: 0;
        }

        &--disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    &__text {
        flex: 1;
        font-family: var(--ff);
        font-size: var(--checkbox-field-label-font-size);
        line-height: 1.5em;
        color: var(--checkbox-field-label-color);
        order: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }
    }

    &__box {
        position: relative;
        width: 16px;
        height: 16px;
        margin-top: calc(((var(--checkbox-field-label-font-size) * 1.5) - 16px) / 2);
        margin-right: 12px;
        background-color: var(--checkbox-field-box-background-color);
        border: 1px solid var(--checkbox-field-box-border-color);
        border-radius: 3px;
        order: 1;
        transition: background-color 300ms, border-color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 10px;
            height: 8px;
            background: url("../medias/images/icons/checkmark.svg") no-repeat center/contain;
            transition: transform 300ms;
        }
    }

    // Input checked
    &__input:checked ~ &__box {
        background-color: var(--checkbox-field-box-background-color-active);
        border-color: var(--checkbox-field-box-border-color-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &--inline &__options {
        margin-top: -15px;
    }

    &--two-columns &__options {
        @media screen and (min-width: 601px) {
            column-count: 2;
            -moz-column-count: 2;
            -webkit-column-count: 2;
        }
    }

    &--inline &__label {
        display: inline-flex;
        margin: 15px 25px 0 0;
    }

    &--two-columns &__label {
        width: fit-content;
    }
}
