.c-home-fast-links-list {
    --home-fast-links-list-gap-x: var(--x-30);
    --home-fast-links-list-gap-y: 4px;
    --home-fast-links-list-nb-columns: 3;

    @media screen and (max-width: 1000px) {
        --home-fast-links-list-nb-columns: 2;
    }

    @media screen and (max-width: 600px) {
        --home-fast-links-list-gap-x: 0px;
        --home-fast-links-list-nb-columns: 1;
    }
}

.c-home-fast-links-list {
    &__container {
        width: 100%;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (var(--home-fast-links-list-gap-x)));
        margin-left: calc(0px - (var(--home-fast-links-list-gap-x) / 2));
        margin-right: calc(0px - (var(--home-fast-links-list-gap-x) / 2));
        margin-bottom: calc(0px - (var(--home-fast-links-list-gap-y)));
    }

    &__list-item {
        display: flex;
        justify-content: center;
        width: calc(100% / var(--home-fast-links-list-nb-columns));
        padding: 0 calc(var(--home-fast-links-list-gap-x) / 2);
        margin-bottom: var(--home-fast-links-list-gap-y);
    }
}
