@use "../../base/typography";

.c-information-card {
    display: flex;
    padding: var(--y-nus-30) var(--x-nus-40);
    background-color: rgb(var(--rgb-blue-navy));

    // Svg container
    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: rgb(var(--rgb-blue-dark-navy));
        border-radius: 50%;
        margin-right: var(--x-nus-25);

        @media screen and (max-width: 600px) {
            order: 1;
            margin-right: 0;
            margin-left: var(--x-nus-25);
        }
    }

    &__svg {
        width: 22px;
        height: 22px;
        stroke: rgb(var(--rgb-white));
    }

    // Content
    &__content {
        flex: 1;
    }

    // Content top
    &__content-top {
        display: flex;
        flex-direction: column;
    }

    &__content-top:not(:last-child) {
        margin-bottom: var(--y-nus-28);
    }

    &__title {
        font-size: var(--fz-25);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__subtitle {
        font-size: var(--fz-22);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        margin-top: 14px;
    }

    // Content middle
    &__content-middle {
        display: flex;
        flex-direction: column;
    }

    &__content-middle:not(:last-child) {
        margin-bottom: var(--y-nus-25);
    }

    &__contact-person-title {
        font-size: var(--fz-13);
        font-weight: 500;
        line-height: 1.5em;
        color: rgba(var(--rgb-white), 0.7);
        margin-bottom: 15px;
    }

    &__contact-person-name {
        font-size: var(--fz-21);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
    }

    &__contact-person-job {
        font-size: var(--fz-15);
        font-style: italic;
        line-height: 1.5em;
        color: rgba(var(--rgb-white), 0.7);
        margin-top: 5px;
    }

    // Content bottom
    &__content-bottom {
        display: flex;
        flex-direction: column;
    }

    &__contact-person-email {
        width: fit-content;
    }

    &__contact-person-email:not(:last-child) {
        margin-bottom: 10px;
    }

    &__contact-person-email,
    &__contact-person-phone {
        @extend .c-underlined-link-white;
    }

    &__contact-person-phone-post {
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
    }

    // Top left - social medias
    &__social-medias-element {
        margin-top: var(--y-nus-30);
    }

    &__social-medias-list {
        display: flex;
    }

    &__social-medias-list-item:not(:last-child) {
        margin-right: 17px;
    }

    &__social-media {
        display: block;
        width: 20px;
        height: 20px;
    }

    &__social-media-svg {
        width: 100%;
        height: 100%;
        fill: rgba(var(--rgb-white), 0.6);
        transition: fill 300ms;
    }

    &__social-media:hover &__social-media-svg {
        fill: rgb(var(--rgb-white));
    }
}
