@use "../../abstract/placeholders";
@use "../../base/typography";

.c-search-result-card {
    display: flex;
    flex-direction: column;
    padding: var(--y-nus-50) 0;

    @at-root .c-search-results-list__list-item:first-child & {
        padding-top: 0;
    }

    @at-root .c-search-results-list__list-item:last-child & {
        padding-bottom: 0;
    }

    @at-root .c-search-results-list__list-item:not(:last-child) & {
        border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.3);
    }

    &__category {
        @extend %tag;
        margin-bottom: 15px;
    }

    &__title {
        font-size: var(--fz-22);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-blue-navy));
        transition: color 300ms;

        mark {
            color: rgb(var(--rgb-red));
            transition: color 300ms;
        }
    }

    &:hover &__title {
        color: rgb(var(--rgb-blue-hover));

        mark {
            color: rgb(var(--rgb-blue-hover));
        }
    }

    &__text {
        display: inline-block;
        font-size: var(--fz-16);
        line-height: 1.7em;
        color: rgb(var(--rgb-blue-navy));
        margin-top: 15px;

        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;

        mark {
            color: rgb(var(--rgb-red));
        }
    }

    &__link {
        @extend .c-underlined-link-blue-navy;
        width: fit-content;
        margin-top: var(--y-nus-25);
    }

    &:hover &__link {
        @extend %c-underlined-link-blue-navy-hover;
    }
}
