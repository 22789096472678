.c-button-back-to-list {
    display: flex;
    align-items: center;
    padding: var(--y-nus-25) var(--x-nus-30);
    background-color: rgb(var(--rgb-blue-navy));
    transition: background-color 300ms;

    &:hover {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__svg {
        width: 11px;
        height: 6px;
        transform: rotate(90deg);
        stroke: rgb(var(--rgb-white));
        margin-right: 12px;
    }

    &__text {
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
    }
}
