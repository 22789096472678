/*
|--------------------------------------------------------------------------
| October
|--------------------------------------------------------------------------
|
| CSS pour modifier des styles qui proviennent d'October.
| Par exemple la barre de loading utilisé pour les requêtes AJAX.
|
*/

.oc-progress-bar {
    background-color: rgb(var(--rgb-blue-hover)) !important;
}

.alert.alert-success {
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 1.3;
    color: rgb(var(--rgb-black));

    button {
        display: none;
    }
}

.c-form .oc-loading { // La classe pour le loading loading sur les boutons submit ajouté par le framework october
    pointer-events: none;

    &::after {
        margin-left: 10px;
    }
}
