.c-home-fast-search-and-home-banner {
   --home-fast-search-and-home-banner-home-banner-element-margin-left: calc(0px - 9.4697%); // 125/1320

    @media screen and (max-width: 800px) {
        --home-fast-search-and-home-banner-home-banner-element-margin-left: 0px;
    }
}

.c-home-fast-search-and-home-banner {
    position: relative;
    display: flex;
    align-items: center;
    // Note : Espace viewport bannière moins un petit aperçu de la prochaine section var(--y-30) + var(--fz-45)
    height: calc(100vh - var(--alerts-height) - var(--header-height) - var(--y-30) - var(--fz-45));
    min-height: 550px;
    padding-top: var(--y-30);
    padding-bottom: var(--y-40);
    transition: height 500ms var(--in-out-cubic);

    @media screen and (max-width: 800px) {
        flex-direction: column;
        height: auto;
    }

    &__home-fast-search-element {
        position: relative;
        display: flex;
        align-items: center;
        width: 46.2121%; // 610/1320
        max-height: 100%;
        overflow: hidden;
        z-index: 1;

        @media screen and (max-width: 800px) {
            width: 100%;
            max-height: none;
        }
    }

    &__home-banner-element {
        flex: 1;
        height: 100%;
        margin-left: var(--home-fast-search-and-home-banner-home-banner-element-margin-left);

        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }
}
