@use "sass:math";

// LES TITRES ----------
// Très Gros titre (h1)
.c-huge-title {
    font-size: var(--fz-55);
    font-weight: 600;
    line-height: 1.3em;
}

// Gros titre (h2)
.c-large-title {
    font-size: var(--fz-40);
    font-weight: 600;
    line-height: 1.3em;
}

// Moyen titre (h3)
.c-medium-title {
    font-size: var(--fz-30);
    font-weight: 600;
    line-height: 1.3em;
}

// Petit titre (h4)
.c-small-title {
    font-size: var(--fz-25);
    font-weight: 600;
    line-height: 1.3em;
}


// LES TEXTES ----------
// Le paragraphe
.c-paragraph {
    font-size: var(--paragraph-font-size);
    line-height: var(--paragraph-line-height);
    color: rgb(var(--rgb-blue-navy));
}

// L'introduction
.c-introduction  {
    font-size: var(--fz-22);
    line-height: 1.5em;
}

// La note
.c-note {
    font-family: var(--ff);
    font-size: var(--fz-14);
    line-height: 1.7em;
    color: rgb(var(--rgb-grey-note));
    white-space: normal;
}

// La citation
.c-quote {
    padding-left: 30px;
    border-left: 10px solid rgb(var(--rgb-red));

    @media screen and (max-width: 1024px) {
        padding-left: 25px;
        border-width: 8px;
    }
    @media screen and (max-width: 768px)  {
        padding-left: 20px;
        border-width: 6px;
    }
    @media screen and (max-width: 500px)  {
        padding-left: 15px;
        border-width: 4px;
    }

    &, p {
        font-family: var(--ff);
        font-size: var(--fz-20);
        line-height: 1.5em;
        margin: 0;
    }
}


// LES LIENS ----------
// Le lien surligné
.c-underlined-link-dynamic {
    @extend .c-paragraph;
    text-decoration: none;

    span {
        color: rgb(var(--rgb-blue-navy));
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-blue-navy), 0.5);
        transition: color 300ms, box-shadow 300ms;
    }

    // Icones liens externes et téléchargement
    svg {
        width: math.div(18, 14) + em;
        height: math.div(18, 14) + em;
        vertical-align: text-top;
        fill: rgb(var(--rgb-blue-navy));
        transition: fill 300ms;
        margin-left: math.div(5, 14) + em;
    }

    @at-root %c-underlined-link-dynamic-hover {
        span {
            color: rgb(var(--rgb-blue-hover));
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-blue-hover), 0.5);
        }

        svg {
            fill: rgb(var(--rgb-blue-hover));
        }
    }

    &:hover {
        @extend %c-underlined-link-dynamic-hover;
    }
}

// Le lien surligné blue navy
.c-underlined-link-blue-navy {
    font-size: var(--fz-14);
    font-weight: 500;
    line-height: 1.5em;

    span {
        color: rgb(var(--rgb-blue-navy));
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-blue-navy), 0.5);
        transition: color 300ms, box-shadow 300ms;
    }

    // Icones liens externes et téléchargement
    svg {
        width: math.div(18, 14) + em;
        height: math.div(18, 14) + em;
        vertical-align: text-top;
        fill: rgb(var(--rgb-blue-navy));
        stroke: rgb(var(--rgb-blue-navy));
        transition: fill 300ms, stroke 300ms;
        margin-left: math.div(5, 14) + em;
    }

    @at-root %c-underlined-link-blue-navy-hover {
        span {
            color: rgb(var(--rgb-blue-hover));
            box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-blue-hover), 0.5);
        }

        svg {
            fill: rgb(var(--rgb-blue-hover));
            stroke: rgb(var(--rgb-blue-hover));
        }
    }

    &:hover {
        @extend %c-underlined-link-blue-navy-hover;
    }
}

// Le lien surligné blue navy simplifier (aucun span et aucun svg)
.c-underlined-link-blue-navy-simplify {
    font-size: var(--fz-14);
    font-weight: 500;
    line-height: 1.5em;
    color: rgb(var(--rgb-blue-navy));
    box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-blue-navy), 0.5);
    transition: color 300ms, box-shadow 300ms;

    @at-root %c-underlined-link-blue-navy-simplify-hover {
        color: rgb(var(--rgb-blue-hover));
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-blue-hover), 0.5);
    }

    &:hover {
        @extend %c-underlined-link-blue-navy-simplify-hover;
    }
}

// Le lien surligné white
.c-underlined-link-white {
    font-size: var(--fz-14);
    font-weight: 500;
    line-height: 1.5em;

    span {
        color: rgb(var(--rgb-white));
        box-shadow: inset 0 -0.1em 0 0 rgba(var(--rgb-white), 0.5);
        transition: opacity 300ms;
    }

    // Icones liens externes et téléchargement
    svg {
        width: math.div(18, 14) + em;
        height: math.div(18, 14) + em;
        vertical-align: text-top;
        fill: rgb(var(--rgb-white));
        stroke: rgb(var(--rgb-white));
        transition: fill 300ms, stroke 300ms;
        margin-left: math.div(5, 14) + em;
    }

    @at-root %c-underlined-link-white-hover {
        span {
            opacity: 0.6;
        }

        svg {
            fill: rgba(var(--rgb-white), 0.6);
            stroke: rgba(var(--rgb-white), 0.6);
        }
    }

    &:hover {
        @extend %c-underlined-link-white-hover;
    }
}


// LES BOUTONS ----------
// Le bouton plein et vide
.c-button {
    display: inline-flex;
    align-items: center;
    padding: 16px 35px;
    gap: var(--button-gap, 10px);
    font-size: var(--fz-14);
    font-weight: 500;
    line-height: 1.5em;
    color: var(--button-color);
    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    transition: color 300ms, background-color 300ms, border 300ms;

    svg {
        content: "";
        width: var(--button-svg-dimension, 18px);
        height: var(--button-svg-dimension, 18px);
        flex-shrink: 0;
        display: inline-block;
    }

    &--svg-stroke {
        svg {
            fill: none;
            stroke: var(--button-svg-color);
            stroke-width: var(--stroke-width, 1px);
            transition: stroke 300ms;
        }
    }

    &--svg-fill {
        svg {
            fill: var(--button-svg-color);
            stroke: none;
            transition: fill 300ms;
        }
    }

    &--full-blue-navy {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-blue-navy));
        --button-border-color: rgb(var(--rgb-blue-navy));

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-blue-hover));
            --button-border-color: rgb(var(--rgb-blue-hover));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }

    &--full-blue-inoffensive {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-blue-inoffensive));
        --button-border-color: rgb(var(--rgb-blue-inoffensive));

        svg {
            --button-svg-color: rgb(var(--rgb-white));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-blue-hover));
            --button-border-color: rgb(var(--rgb-blue-hover));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }

    &--full-white {
        --button-color: rgb(var(--rgb-blue-navy));
        --button-background-color: rgb(var(--rgb-white));
        --button-border-color: rgb(var(--rgb-white));

        svg {
            --button-svg-color: rgb(var(--rgb-blue-navy));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-blue-hover));
            --button-border-color: rgb(var(--rgb-blue-hover));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }

    &--empty-blue-navy-alpha-30 {
        --button-color: rgb(var(--rgb-blue-navy));
        --button-background-color: transparent;
        --button-border-color: rgba(var(--rgb-blue-navy), 0.3);

        svg {
            --button-svg-color: rgb(var(--rgb-blue-navy));
        }

        &:hover {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-blue-hover));
            --button-border-color: rgb(var(--rgb-blue-hover));

            svg {
                --button-svg-color: rgb(var(--rgb-white));
            }
        }
    }
}


// LES LISTES ----------
// Liste désordonnée et ordonnée
.c-list {
    li {
        @extend .c-paragraph;

        p {
            margin: 0;
        }

        &:not(:first-child) {
            margin-top: 12px;
        }

        ol,
        ul {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 0;

            li {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }

    &--unordered {
        --unordered-list-padding: 20px;
        @media screen and (max-width: 500px) { --unordered-list-padding: 15px; }

        & > li {
            width: calc(100% - var(--unordered-list-padding));
            position: relative;
            left: var(--unordered-list-padding);

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                top: calc(var(--paragraph-line-height) / 2);
                transform: translate(calc(0px - var(--unordered-list-padding)), -50%);
                background-color: rgb(var(--rgb-blue-navy));
                border: 1px solid rgb(var(--rgb-blue-navy));
                border-radius: 50%;
                box-sizing: border-box;
            }

            & > ul li {
                &::before {
                    background-color: transparent;
                }

                & > ul li::before {
                    background-color: rgb(var(--rgb-blue-navy));
                }
            }
        }
    }

    &--ordered {
        --ordered-list-padding: 50px;
        @media screen and (max-width: 768px) { --ordered-list-padding: 25px; }
        @media screen and (max-width: 500px) { --ordered-list-padding: 20px; }
        list-style: none;
        counter-reset: li;

        & > li {
            width: calc(100% - var(--ordered-list-padding));
            position: relative;
            left: var(--ordered-list-padding);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                position: absolute;
                transform: translateX(calc(0px - var(--ordered-list-padding)));
                font-weight: 500;
            }

            ol > li {
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {
                    @media screen and (min-width: 769px) { padding-left: 40px; }

                    &::before {
                        content: counter(li, lower-roman) ".";
                    }
                }
            }
        }
    }
}


// LES TABLEAUX ET ZONES ----------
// Le tableau
.c-table {
    overflow-x: auto;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: rgb(var(--rgb-white));
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgb(var(--rgb-grey-scrollbar-thumb));
    }

    table {
        overflow-x: hidden;
        border-collapse: collapse;
        margin-right: 0 !important;

        tr {
            th,
            td {
                @extend .c-paragraph;
                padding: 20px 35px;
                background-color: rgb(var(--rgb-white));
                border: 1px solid rgb(181, 194, 209); // #B5C2D1

                @media screen and (max-width: 1024px) { padding: 20px 30px; }
                @media screen and (max-width: 768px)  { padding: 18px 25px; }
                @media screen and (max-width: 500px)  { padding: 16px 20px; }

                & > :first-child {
                    margin-top: 0;
                }

                & > *:last-child {
                    margin-bottom: 0 !important;
                }
            }

            th,
            td.table-header {
                font-weight: 600;
                text-align: left;
                color: rgb(var(--rgb-white));
                background-color: rgb(var(--rgb-blue-navy));
                border-top-color: rgb(181, 194, 209); // #B5C2D1
                border-bottom-color: rgb(181, 194, 209); // #B5C2D1

                &:first-child {
                    border-left-color: rgb(181, 194, 209); // #B5C2D1
                }

                &:last-child {
                    border-right-color: rgb(181, 194, 209); // #B5C2D1
                }
            }

            td {
                &.table-secondary-header {
                    background-color: rgb(var(--rgb-grey-background));
                }
            }
        }
    }
}

// Zone d'emphase
.c-emphasis {
    padding: var(--y-35) var(--x-40);
    background-color: rgb(var(--rgb-grey-background));

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0
    }
}

// Zone d'emphase statistique
.c-emphasis-stat {
    padding: var(--y-30) 0;
    border-bottom: 1px solid rgba(var(--rgb-blue-navy), 0.3);

    > * {
        font-size: var(--fz-40);
        font-weight: 600;
        line-height: 1.3em;
        margin: 0;
    }
}
