@use "../../../base/typography";

.c-checkbox-survey-field {
    --checkbox-survey-field-box-background-color: rgb(var(--rgb-white));
    --checkbox-survey-field-box-background-color-active: rgb(var(--rgb-blue-hover));
    --checkbox-survey-field-box-border-color: rgba(var(--rgb-blue-navy), 0.5);
    --checkbox-survey-field-box-border-color-active: rgb(var(--rgb-blue-hover));
}

.c-checkbox-survey-field {
    position: relative;

    &__options {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
    }

    &__label {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
        cursor: pointer;
    }

    // Input disabled
    &__label--disabled {
        pointer-events: none;
    }

    &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__box-and-text {
        flex: 1;
        display: flex;
        align-items: center;
        padding: var(--y-nus-25) var(--x-nus-35) var(--y-nus-25) var(--x-nus-25);
    }

    &__box {
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: var(--x-nus-20);
        background-color: var(--checkbox-survey-field-box-background-color);
        border: 1px solid var(--checkbox-survey-field-box-border-color);
        border-radius: 3px;
        transition: background-color 300ms, border-color 300ms;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 10px;
            height: 8px;
            background: url("../medias/images/icons/checkmark.svg") no-repeat center/contain;
            transition: transform 300ms;
        }
    }

    // Input checked
    &__input:checked ~ &__box-and-text &__box {
        background-color: var(--checkbox-survey-field-box-background-color-active);
        border-color: var(--checkbox-survey-field-box-border-color-active);

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &__text {
        flex: 1;
        font-size: var(--fz-15);
        font-weight: 500;
        line-height: 1.5em;
        transition: color 300ms;

        a {
            @extend .c-underlined-link-dynamic;
            font-size: inherit;
        }

        sup {
            position: relative;
            top: -0.3em;
            font-size: max(0.7em, 1.2rem);
            vertical-align: baseline;
        }
    }

    &__label:hover &__text {
        color: rgb(var(--rgb-blue-hover));
    }

    // Input disabled
    &__label--disabled &__text {
        color: rgb(var(--rgb-grey-note));
    }

    &__picture-container {
        position: relative;
        display: block;
    }

    &__plus-container {
        position: absolute;
        right: 5px;
        bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        background-color: rgb(var(--rgb-white));
        border-radius: 50%;
        transition: background-color 300ms;
        z-index: 2;
    }

    &__picture-container:hover &__plus-container {
        background-color: rgb(var(--rgb-blue-hover));
    }

    &__plus {
        position: relative;
        width: 7px;
        height: 7px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 1.5px;
            background-color: rgb(var(--rgb-blue-navy));
            border-radius: 1.5px;
            transition: background-color 300ms;
            will-change: transform;
        }

        &::before {
            transform: translate3d(-50%, -50%, 0);
        }

        &::after {
            transform: translate3d(-50%, -50%, 0) rotate(90deg);
        }
    }

    &__picture-container:hover &__plus::before,
    &__picture-container:hover &__plus::after {
        background-color: rgb(var(--rgb-white));
    }

    &__picture {
        position: relative;
        width: 95px;
        height: 70px;
        overflow: hidden;

        @media screen and (max-width: 1000px) {
            width: 81px;
            height: 60px;
        }
    }

    &__label--disabled &__picture {
        opacity: 0.5;
    }

    &__picture::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgb(var(--rgb-blue-hover), 0.3);
        opacity: 0;
        transition: opacity 300ms;
        z-index: 1;
    }

    &__label:hover &__picture::before {
        opacity: 1;
    }

    &__picture::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(310deg, rgba(var(--rgb-blue-navy), 0.5) 0%, rgba(var(--rgb-blue-navy), 0) 100%);
    }

    // Erreur
    &__error {
        position: relative;
    }
}
