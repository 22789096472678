.c-textarea-field {
    --textarea-field-color: rgb(var(--rgb-blue-navy));
    --textarea-field-placeholder-color: rgb(var(--rgb-grey-note));
    --textarea-field-background-color: rgb(var(--rgb-grey-background));
    --textarea-field-border-color: rgba(var(--rgb-blue-navy), 0.3);
    --textarea-field-border-color-active: rgb(var(--rgb-blue-navy));
    --textarea-field-border-color-error: rgb(var(--rgb-red-error));

    &--survey {
        --textarea-field-background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
    }
}

.c-textarea-field {
    position: relative;

    &--survey {
        padding: var(--y-nus-25) var(--x-nus-35);
        background-color: rgb(var(--rgb-grey-background-with-blue-navy-alpha-10));
    }

    &__textarea {
        width: 100%;
        height: 43px;
        padding: 8px 0;
        font-family: var(--ff);
        font-size: var(--fz-16);
        line-height: 1.5em;
        color: var(--textarea-field-color);
        background-color: var(--textarea-field-background-color);
        border: 0;
        border-bottom: 1px solid var(--textarea-field-border-color);
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        box-shadow: 0 0 0 100px var(--textarea-field-background-color) inset; // Choisir la couleur voulu pour le input (Cache le background bleu de l'autofill)
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;
        outline: none;
        resize: none;
        overflow: hidden;

        &[style] { // Éviter le skip quand le textarea a une hauteur donnée par la fonction "textareaHeight"
            margin-bottom: 0;
        }
    }

    &__textarea:focus {
        border-color: var(--textarea-field-border-color-active);
    }

    &--error &__textarea {
        border-color: var(--textarea-field-border-color-error);
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--textarea-field-placeholder-color); }
    ::-moz-placeholder { color: var(--textarea-field-placeholder-color); }
    :-ms-input-placeholder {  color: var(--textarea-field-placeholder-color); }
    :-moz-placeholder { color: var(--textarea-field-placeholder-color); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-size: var(--fz-16) !important;
        border-bottom: 1px solid var(--textarea-field-border-color-active) !important;
        -webkit-text-fill-color: var(--textarea-field-color);
        caret-color: var(--textarea-field-color);
    }

    :-webkit-autofill::first-line {
        font-family: var(--ff);
        font-size: var(--fz-16);
    }

    // Erreur
    &--survey .c-form-error {
        position: relative;
    }
}
