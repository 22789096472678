.c-events-list {
    --events-list-gap-x: var(--x-30);
    --events-list-gap-y: var(--y-60);
    --events-list-nb-columns: 3;

    @media screen and (max-width: 1200px) {
        --events-list-nb-columns: 2;
    }

    @media screen and (max-width: 800px) {
        --events-list-gap-x: 0px;
        --events-list-nb-columns: 1;
    }
}

.c-events-list {
    &__container {
        width: 100%;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (var(--events-list-gap-x)));
        margin-left: calc(0px - (var(--events-list-gap-x) / 2));
        margin-right: calc(0px - (var(--events-list-gap-x) / 2));
        margin-bottom: calc(0px - (var(--events-list-gap-y)));
    }

    &__list-item {
        display: flex;
        justify-content: center;
        width: calc(100% / var(--events-list-nb-columns));
        padding: 0 calc(var(--events-list-gap-x) / 2);
        margin-bottom: var(--events-list-gap-y);
    }

    &__list-item--large {
        @media screen and (min-width: 1201px){
            width: 100%;
        }
    }
}
