@use "../../base/typography";

.c-call-to-action-menu-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    // Picture
    &__picture-container {
        flex: 1;
        // Note : Max-height pour respecter un ratio d'image de 185px/230px
        max-height: 230px;
    }

    &__picture {
        position: relative;
        height: 100%;
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // Content
    &__content {
        display: flex;
        flex-direction: column;
        padding: var(--y-nus-55) var(--x-nus-30);
        background-color: rgb(var(--rgb-blue-navy));
    }

    &__title {
        font-size: var(--fz-16);
        font-weight: 600;
        line-height: 1.6em;
        color: rgb(var(--rgb-white));
    }

    &__link {
        @extend .c-underlined-link-white;
        margin-top: var(--y-nus-20);
    }

    &:hover &__link {
        @extend %c-underlined-link-white-hover;
    }
}
